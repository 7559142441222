import React, { useEffect, useState } from "react";

import Footer from "common/footer/footer";
import Header from "common/header/header";
import PropTypes from "prop-types";
import { Box, CircularProgress } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import projectDummy from "dummy/dataProject";
import { ProjectInterface } from "model/interfaces/project";
import SpaceingAppBar from "common/components/spacingappbar";
import BodyProjectDetail from "./components/bodyprojectdetail";
import Loader from "common/components/Loader";
import SlideDiscover from "common/components/slideDiscover";
import { ApiController } from "common/api/api_controller";

function ProjectDetailPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState<ProjectInterface | null>(null);
  const [dataDiscover, setDataDiscover] = useState<ProjectInterface[] | null>(
    null
  );
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    findData();
    getDataDiscover();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const findData = async () => {
    setLoading(true);
    let haveDetail = false;

    let respon = await ApiController.getSelectedProject(id ?? "");
    haveDetail = respon != null;

    setData({ ...respon });

    if (!haveDetail) navigate("/notfound", { replace: true });
  };

  const getDataDiscover = async () => {
    let respon = await ApiController.getDiscoverProject(id ?? "");

    setDataDiscover([...respon]);
  };

  useEffect(() => {
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDiscover, data]);

  return (
    <>
      {/* <Header />
      <SpaceingAppBar /> */}
      <Box height={{ xs: "22px", lg: "107px" }}></Box>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <BodyProjectDetail data={data ?? null} />
          <Box sx={{ height: { xs: "0px", lg: "69px" } }} />
          <SlideDiscover dataProject={dataDiscover} dataProduct={null} />
        </>
      )}
      {/* <Footer /> */}
    </>
  );
}

// ProjectDetailPage.propTypes = {
//   id: PropTypes.string.isRequired,
// };

export default ProjectDetailPage;
