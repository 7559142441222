import { Button } from "@mui/material";
import PropTypes from "prop-types";

const ZuluButton = (props: any) => {
  const { onClick, borderColor, color, width, height, children } = props;
  return (
    <Button
      type="submit"
      variant="outlined"
      onClick={onClick}
      color={color ?? "primary"}
      sx={{
        borderRadius: "30px",
        borderColor: { borderColor } ?? "#000",
        width: width ?? "fit-content",
        height: height ?? "auto",
      }}
    >
      {children}
    </Button>
  );
};

ZuluButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string,
  width: PropTypes.any,
  height: PropTypes.any,
  borderColor: PropTypes.string,
  children: PropTypes.any,
};

export default ZuluButton;

// export default Const ZuluButton=(props: any) {

//     return <Div>Read More</Div>;
// }

// const Div = styled.div`
//   display: flex;
//   flex-direction: row;
//   max-width: 80px;
//   justify-content: center;
//   align-items: center;
//   border-radius: 30px;
//   padding-top: 13px;
//   padding-right: 69px;
//   padding-bottom: 13px;
//   padding-left: 69px;
//   border-color: rgba(65, 64, 66, 1);
//   border-width: 1px;
//   border-style: solid;
//   color: rgba(65, 64, 66, 1);
//   font-size: 16px;
//   line-height: 21px;
//   letter-spacing: 0%;
//   text-align: left;
//   font-family: "Akkurat", sans-serif;
// `;
// }
