import { Container, Grid } from "@mui/material";
import CardProduct from "common/components/cardProduct";
import { ProductInterface } from "model/interfaces/product";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

const ProductGrid = (props: { allData: ProductInterface[] }) => {
  const { allData } = props;

  const navigate = useNavigate();

  return (
    // <Container maxWidth={"xl"}>
    <Grid
      container
      direction="row"
      // justifyContent="center"
      // alignItems="stretch"
      spacing={2.5}
      px={{ xs: "22px", lg: "71px" }}
      // columnSpacing={3}
      // rowSpacing={"23px"}
    >
      {allData?.map((item, index) => (
        <Grid
          item
          xs={12}
          md={4}
          key={index}
          // maxHeight={"507px"}
          // maxWidth={"639px"}
          // border={1}
        >
          {item.imageCover != null ? (
            <CardProduct
              key={index}
              title={item.productName}
              year={item.year}
              isNew={item.isNew}
              image={item.imageCover[0]}
              link={() => {
                navigate("/product/" + item.pathUrl);
              }}
              maxWidthCard={"639px"}
              maxHeightCard={"507px"}
              fontSizeTitle={{ xs: "20px", lg: "24px" }}
              lineHeightTitle={{ xs: "25px", lg: "28px" }}
              heightImage={263.14}
              widthImage={419.33}
            />
          ) : (
            <></>
          )}
        </Grid>
      ))}
    </Grid>
    // </Container>
  );
};

ProductGrid.prototype = {
  allData: PropTypes.arrayOf<ProductInterface>,
};

export default ProductGrid;
