import React, { useEffect, useState } from "react";
import FullImage from "./components/fullimage";
import Header from "../../common/header/header";
import Descpage from "./components/descpage";
import ProjectHightlight from "./components/projecthightlight";
import OurTeam from "./components/ourteam";
import OurProduct from "./components/ourproduct";
import Footer from "common/footer/footer";
import dataTeam from "dummy/dataTeam";
import dataSlideLandingPage from "dummy/dataSlideLandingPage";
import ResponsiveImage from "./components/responsiveimage";
import { CoverInterface } from "model/interfaces/cover";
import { ApiController } from "common/api/api_controller";
import { AboutLandingPageInterface } from "model/interfaces/about";

function LandingPage() {
  const [data, setData] = useState<AboutLandingPageInterface | null>(null);
  const [isLoading, setLoading] = useState(false);

  async function getData() {
    setLoading(true);
    let respon = await ApiController.getAboutLandingPage();

    setData({ ...respon });
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setLoading(false);
    // console.log(data);
  }, [data]);

  return (
    <>
      {/* <Header /> */}

      <FullImage />
      <Descpage desc={data?.desc ?? null} />
      <ResponsiveImage image={data?.image ?? []} />
      <ProjectHightlight />
      {/* <OurTeam dataTeam={dataTeam} /> */}
      <OurProduct />
      {/* <Footer /> */}
    </>
  );
}

export default LandingPage;
