import { Box, Container, Grid, Typography } from "@mui/material";
import ZuluConfig from "common/config/zulu_config";
import Colors from "common/const/colorzulu";
import FontFamilyName from "common/const/fontfamily";
import ImageAssets from "common/const/imageassets";
import { ServiceInterface } from "model/interfaces/service";
import { ImagesType } from "model/types/imagesType";
import PropTypes from "prop-types";

const Manufacturing = (props: { data?: ServiceInterface }) => {
  const { data } = props;
  const numberService: string = data?.number ?? "06";
  const nameService: string = data?.name ?? "Manufacturing\nService";
  const listImage: ImagesType[] = data?.image ?? [];
  const descService: string = data?.desc ?? ``;
  return (
    <>
      <Grid
        container
        direction={"row"}
        rowSpacing={{ xs: "20px", md: "40px" }}
        columnSpacing={{ xs: "20px", md: "40px" }}
      >
        <Grid item xs={4} md={6}>
          <Typography
            sx={{
              color: Colors.textGrey,
              fontFamily: FontFamilyName.BasisGrotesquePro,
              fontSize: { xs: "24px", md: "72px" },
              fontWeight: 500,
              whiteSpace: "pre-wrap",
              lineHeight: { xs: "36px", md: "108px" },
            }}
          >
            {numberService}
          </Typography>
        </Grid>

        <Grid item xs={8} md={6}>
          <Typography
            sx={{
              color: Colors.textGrey,
              fontFamily: FontFamilyName.BasisGrotesquePro,
              fontSize: { xs: "24px", md: "72px" },
              fontWeight: 500,
              whiteSpace: "pre-wrap",
              lineHeight: { xs: "36px", md: "108px" },
            }}
          >
            {nameService}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          {listImage.length > 0 ? (
            listImage?.map((item, index) => {
              return (
                <Box
                  key={index}
                  component={"img"}
                  alt={item.alt}
                  src={ZuluConfig.baseUrlImage + item.baseUrl}
                  loading="lazy"
                  sx={{
                    objectFit: "cover",
                    backgroundRepeat: "no-repeat",
                    width: "100%",
                  }}
                />
              );
            })
          ) : (
            <Box
              component={"img"}
              src={ImageAssets.sampleBodyProductDetail}
              sx={{
                objectFit: "cover",
                backgroundRepeat: "no-repeat",
                width: "100%",
              }}
            />
          )}
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography
            sx={{
              color: Colors.textGrey,
              fontFamily: FontFamilyName.Akkurat,
              fontSize: { xs: "12px", md: "20px" },
              fontWeight: 400,
              whiteSpace: "pre-wrap",
              lineHeight: { xs: "18px", md: "25.34px" },
            }}
          >
            {descService}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

Manufacturing.propTypes = {};

export default Manufacturing;
