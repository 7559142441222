import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import Footer from "common/footer/footer";
import Header from "common/header/header";
import SpaceingAppBar from "common/components/spacingappbar";
import { useLocation } from "react-router-dom";
import ZuluConfig from "common/config/zulu_config";
import { Box } from "@mui/material";

const useStyles = makeStyles({
  page: {
    background: "#f9f9f9",
    width: "100%",
  },
});

const Layout = ({ children }: any) => {
  const classes = useStyles();
  const location = useLocation();

  return (
    <div>
      <Header />

      <Container
        disableGutters
        maxWidth={ZuluConfig.withMaxWidth ? "xl" : false}
      >
        {/* <Header /> */}
        {location.pathname === "/" ? <></> : <SpaceingAppBar />}

        {children}

        <Box height={{ xs: "36px", md: "82px" }} />
        <Footer />
      </Container>
    </div>
  );
};

export default Layout;
