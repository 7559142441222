import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  Divider,
  Link,
  ImageListItem,
} from "@mui/material";
import ZuluButton from "common/components/zulubutton";
import { Colors, FontFamilyName, ImageAssets } from "common/const/const_config";
import { ImagesType } from "model/types/imagesType";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ZuluConfig from "common/config/zulu_config";
import { useEffect, useState } from "react";
import { getImageSize } from "react-image-size";
import ImageRenderProject from "./image_render_project";

const ProjectList = (props: {
  onClick: any;
  botomDevider: any;
  topDevider: any;
  projectName: any;
  year: any;
  client: any;
  arrImages: ImagesType[];
  bgColor: any;
  textColor: any;
  btnColor: any;
  isLarge: boolean;
}) => {
  const {
    onClick,
    botomDevider,
    topDevider,
    projectName,
    year,
    client,
    arrImages,
    bgColor,
    textColor,
    btnColor,
    isLarge,
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  // useEffect(() => {
  //   runImageSize();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const DescMobile = () => {
    return (
      <Grid
        direction={"column"}
        container
        bgcolor={bgColor ?? Colors.primaryRed}
        sx={{
          px: "21px",
          py: "19px",
        }}
      >
        <Grid item>
          <Typography
            sx={{
              color: textColor ?? Colors.secondayWhite,
              fontSize: { xs: "11px", lg: "16px" },
              fontFamily: FontFamilyName.Akkurat,
              fontWeight: 400,
            }}
          >
            Project
          </Typography>
        </Grid>
        <Grid item height={"10px"} />

        <Grid item>
          <Typography
            sx={{
              color: textColor ?? Colors.secondayWhite,
              fontSize: { xs: "24px", lg: "48px" },
              fontFamily: FontFamilyName.BasisGrotesquePro,
              fontWeight: 400,
              // lineHeight: "31px",
              lineHeight: { xs: "14px", lg: "31px" },
            }}
          >
            {projectName}
          </Typography>
        </Grid>
        <Grid item height={"20px"} />

        <Grid item container justifyContent={"center"} xs={12}>
          <ImageBuilder />
        </Grid>

        <Grid item height={"5px"} />

        <Grid item>
          <Grid container direction="row">
            <Grid item>
              <Typography
                sx={{
                  color: textColor ?? Colors.secondayWhite,
                  fontSize: "11px",
                  fontFamily: FontFamilyName.Akkurat,
                  fontWeight: 400,
                  pr: "42px",
                }}
              >
                Year
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                sx={{
                  color: textColor ?? Colors.secondayWhite,
                  fontSize: "11px",
                  fontFamily: FontFamilyName.Akkurat,
                  fontWeight: 400,
                  pr: "79px",
                }}
              >
                {year}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                sx={{
                  color: textColor ?? Colors.secondayWhite,
                  fontSize: "11px",
                  fontFamily: FontFamilyName.Akkurat,
                  fontWeight: 400,
                  pr: "35px",
                }}
              >
                Client
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                sx={{
                  color: textColor ?? Colors.secondayWhite,
                  fontSize: "11px",
                  fontFamily: FontFamilyName.Akkurat,
                  fontWeight: 400,
                }}
              >
                {client}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const DescDekstop = () => {
    return (
      <Grid
        container
        direction={"row"}
        alignItems="stretch"
        bgcolor={bgColor ?? Colors.primaryRed}
        pt={{ xs: "18px", lg: "80px" }}
        pb={{ xs: "18px", lg: "80px" }}
        pl={{ xs: "21px", lg: "72px" }}
        pr={{ xs: "22px", lg: "46px" }}
      >
        <Grid item xs={4}>
          <Grid container direction={"column"} maxWidth={"301px"}>
            <Grid item>
              <Typography
                sx={{
                  color: textColor ?? Colors.secondayWhite,
                  fontSize: { xs: "11px", lg: "16px" },
                  fontFamily: FontFamilyName.Akkurat,
                  fontWeight: 400,
                }}
              >
                Project
              </Typography>
            </Grid>
            <Grid item height={"1px"} />

            <Grid item>
              <Typography
                sx={{
                  color: textColor ?? Colors.secondayWhite,
                  fontSize: { xs: "24px", lg: "48px" },
                  fontFamily: FontFamilyName.BasisGrotesquePro,
                  fontWeight: 400,
                  lineHeight: { xs: "28px", lg: "56.59px" },
                }}
              >
                {projectName}
              </Typography>
            </Grid>
            <Grid item height={"54px"} />

            <Grid item>
              <Grid container direction={"row"}>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      color: textColor ?? Colors.secondayWhite,
                      fontSize: "16px",
                      fontFamily: FontFamilyName.Akkurat,
                      fontWeight: 400,
                      lineHeight: "21px",
                    }}
                  >
                    Year
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      color: textColor ?? Colors.secondayWhite,
                      fontSize: "16px",
                      fontFamily: FontFamilyName.Akkurat,
                      fontWeight: 400,
                      lineHeight: "21px",
                    }}
                  >
                    Client
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              height={"5px"}
              // bgcolor={Colors.infoBlack}
            />
            <Grid item>
              <Grid container direction={"row"}>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      color: textColor ?? Colors.secondayWhite,
                      fontSize: "32px",
                      fontFamily: FontFamilyName.BasisGrotesquePro,
                      fontWeight: 400,
                      lineHeight: "41.31px",
                    }}
                  >
                    {year}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      color: textColor ?? Colors.secondayWhite,
                      fontSize: "32px",
                      fontFamily: FontFamilyName.BasisGrotesquePro,
                      fontWeight: 400,
                      lineHeight: "41.31px",
                    }}
                  >
                    {client}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              height={"73px"}
              // bgcolor={Colors.infoBlack}
            />
            <Grid item>
              <ZuluButton
                onClick={onClick}
                color={btnColor ?? "secondary"}
                width={"183px"}
                // height={"41px"}
              >
                <Typography
                  sx={{
                    fontFamily: FontFamilyName.Akkurat,
                    fontSize: "16px",
                    fontWeight: 400,
                    // px: "40px",
                    py: "5px",
                  }}
                >
                  Read More
                </Typography>
              </ZuluButton>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={8} container>
          <Grid container direction={"row"} columnSpacing={"50px"}>
            <ImageBuilder />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  let ImageBuilder = (): JSX.Element => {
    let _isLarge = isLarge ?? false;
    return (
      <>
        {arrImages.map((item, index) => {
          let val;
          if (isMobile) {
            if (item.primary) {
              val = (
                <Box height={"400px"} width={"100%"} key={index}>
                  <ImageRenderProject
                    imageData={item}
                    heightImage={400}
                    widthImage={500}
                  />
                </Box>

                // <Box
                //   key={index}
                //   component="img"
                //   alt={item.alt}
                //   src={ZuluConfig.baseUrlImage + item.baseUrl}
                //   sx={{
                //     width: "100%",
                //     objectFit: "cover",
                //     backgroundRepeat: "no-repeat",
                //     // width: "100%",
                //     height: "197px",
                //     // width: { xs: "331px", lg: "528px" },
                //   }}
                // />
              );
            }
          } else {
            val = (
              <Grid
                item
                key={index}
                xs={_isLarge ? 5 : 7}
                sx={{
                  // maxWidth: _isLarge ? "290px" : "490px",
                  maxHeight: "400px",
                  // backgroundImage: item.baseUrl,
                  // border: 1,
                }}
              >
                {item != null ? (
                  <ImageRenderProject
                    imageData={item}
                    heightImage={400}
                    widthImage={_isLarge ? 390 : 490}
                  />
                ) : (
                  <></>
                )}

                {/* {await runImageSize(
                  ZuluConfig.baseUrlImage + item.baseUrl,
                  item.alt,
                  _isLarge ? 290 : 490,
                  400
                )} */}

                {/* <Box
                  component="img"
                  alt={item.alt}
                  src={ZuluConfig.baseUrlImage + item.baseUrl}
                  // {...runImageSize(
                  //   ZuluConfig.baseUrlImage + item.baseUrl,
                  //   _isLarge ? 290 : 490,
                  //   400
                  // )}
                  sx={{
                    //   maxWidth: _isLarge ? "290px" : "490px",
                    //   height: "400px",
                    height: "100%",
                    objectFit: "contain",
                    // backgroundRepeat: "no-repeat",
                    width: "100%",

                    //   width: { xs: "331px", lg: "490px" },
                  }}
                /> */}
              </Grid>
            );

            _isLarge = !_isLarge;
          }

          return val;
        })}
      </>
    );
  };

  return (
    <>
      {topDevider == null ? (
        <Divider variant="fullWidth" orientation="horizontal" />
      ) : topDevider ? (
        <Divider variant="fullWidth" orientation="horizontal" />
      ) : (
        <></>
      )}

      {isMobile ? <DescMobile /> : <DescDekstop />}
    </>
  );
};

ProjectList.propTypes = {
  onClick: PropTypes.func.isRequired,
  botomDevider: PropTypes.bool,
  topDevider: PropTypes.bool,
  projectName: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
  arrImages: PropTypes.array.isRequired,
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  btnColor: PropTypes.string,
  isLarge: PropTypes.bool,
};

export default ProjectList;
