import { Box, Divider } from "@mui/material";
import SpaceingAppBar from "common/components/spacingappbar";
import Footer from "common/footer/footer";
import Header from "common/header/header";
import AboutService from "./components/aboutservice";
import UserResearch from "./components/userresearch";
import IndustrialDesign from "./components/industrialdesign";
import ApparelDesign from "./components/appareldesign";
import SoftwareEngineering from "./components/softwareengineering";
import HardwareEngineering from "./components/hardwareengineering";
import Manufacturing from "./components/manufacturing";
import { ServiceInterface } from "model/interfaces/service";
import { ApiController } from "common/api/api_controller";
import { useEffect, useState } from "react";

const Service = () => {
  const [data, setData] = useState<ServiceInterface[]>([]);
  const [isLoading, setLoading] = useState(false);

  async function getData() {
    setLoading(true);
    let respon = await ApiController.getAllService();

    setData([...respon]);
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setLoading(false);
    // console.log(data);
  }, [data]);

  return (
    <>
      {/* <Header />
      <SpaceingAppBar /> */}
      {/* <Box height={{ xs: "40px", md: "142px" }} /> */}
      <Box px={{ xs: "21px", md: "70px" }}>
        <Box height={{ xs: "40px", md: "142px" }} />
        <AboutService data={data[0] ?? null} />
        <Box height={{ xs: "50px", md: "111px" }} />
        <UserResearch data={data[1] ?? null} />
        <Box height={{ xs: "30px", md: "80px" }} />
        <Divider variant="fullWidth" orientation="horizontal" />
        <Box height={{ xs: "30px", md: "80px" }} />
        <IndustrialDesign data={data[2] ?? null} />
        <Box height={{ xs: "30px", md: "80px" }} />
        <Divider variant="fullWidth" orientation="horizontal" />
        <Box height={{ xs: "30px", md: "80px" }} />
        <ApparelDesign data={data[3] ?? null} />
        <Box height={{ xs: "30px", md: "80px" }} />
        <Divider variant="fullWidth" orientation="horizontal" />
        <Box height={{ xs: "30px", md: "80px" }} />
        <SoftwareEngineering data={data[4] ?? null} />
        <Box height={{ xs: "30px", md: "80px" }} />
        <Divider variant="fullWidth" orientation="horizontal" />
        <Box height={{ xs: "30px", md: "80px" }} />
        <HardwareEngineering data={data[5] ?? null} />
        <Box height={{ xs: "30px", md: "80px" }} />
        <Divider variant="fullWidth" orientation="horizontal" />
        <Box height={{ xs: "30px", md: "80px" }} />
        <Manufacturing data={data[6] ?? null} />
      </Box>
      {/* <Box height={"10vh"} /> */}
      {/* <Footer /> */}
    </>
  );
};

export default Service;
