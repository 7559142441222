import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import Colors from "common/const/colorzulu";
import FontFamilyName from "common/const/fontfamily";
import PropTypes from "prop-types";
import ZuluButton from "./zulubutton";
import { ImagesType } from "model/types/imagesType";
import ZuluConfig from "common/config/zulu_config";
import { getImageSize } from "react-image-size";
import { useState, useEffect } from "react";
import CircleItem from "./circle_item";

const CardProduct = (props: {
  link: () => void;
  title: string;
  year: number;
  isNew: boolean;
  maxWidthCard: string;
  maxHeightCard: string;
  heightImage: number;
  widthImage: number;
  image: ImagesType;
  fontSizeTitle: any;
  lineHeightTitle: any;
}) => {
  const {
    link,
    title,
    year,
    isNew,
    image,
    maxWidthCard,
    maxHeightCard,
    fontSizeTitle,
    lineHeightTitle,
    heightImage,
    widthImage,
  } = props;

  const [imageSize, setImageSize] = useState({
    width: 0,
    height: 0,
    imageProps: {},
  });
  const [isLoading, setIsLoading] = useState(false);

  const runImageSize = async () => {
    setIsLoading(true);
    let res = await getImageSize(ZuluConfig.baseUrlImage + image.baseUrl);
    let imageProps = {};
    let biggerWidthThanDefault = res.width > widthImage;
    let biggerHeightThanDefault = res.height > heightImage;

    //islandscape
    if (res.width > res.height) {
      if (biggerWidthThanDefault) {
        imageProps = { width: "100%", height: "100%" };
      } else {
        console.log(heightImage - res.height);
        if (heightImage - res.height > 100)
          imageProps = {
            height: `${heightImage}px`,
            width: "100%",
          };
      }

      //ispotrait
    } else if (res.height > res.width) {
      if (biggerHeightThanDefault) {
        imageProps = { width: "100%", height: "100%" };
      } else {
        if (widthImage - res.width < 100)
          imageProps = { width: `${widthImage}px` };
      }

      //issquare
    } else if (res.width === res.height) {
      imageProps = { width: "100%", height: "100%" };
    }

    setImageSize({ ...res, imageProps: imageProps });
    setIsLoading(false);
  };

  useEffect(() => {
    runImageSize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   console.log(imageSize);
  // }, [imageSize]);

  return (
    <>
      <Card elevation={0} onClick={link}>
        <CardActionArea>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            // maxWidth={{ xs: "auto", lg: maxWidthCard }}
            // maxHeight={{ xs: "auto", lg: maxHeightCard }}
            // spacing={1}
          >
            <Grid
              item
              xs={12}
              alignItems="stretch"
              // border={1}
              // width={`${widthImage}px`}
              // height={`${heightImage}px`}
              container
            >
              <Box
                width={"100%"}
                // height={"100%"}
                // "100%"
                height={`${heightImage}px`}
                // {{ xs: "207.37px", lg: "300px" }}
                justifyContent="center"
                alignItems="center"
                display={"flex"}
                // border={1}
              >
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  <Box
                    {...imageSize?.imageProps}
                    // width={"100%"}
                    // height={"auto"}
                    // maxHeight={"100%"}
                    // {...srcset(ZuluConfig.baseUrlImage + image.baseUrl)}
                    // object-position: 100% 0;
                    component={"img"}
                    alt={image.alt}
                    src={ZuluConfig.baseUrlImage + image.baseUrl}
                    // display={"flex"}
                    sx={{
                      // objectPosition: "100% 0;",
                      objectFit: "cover",
                    }}
                  />
                )}
              </Box>
            </Grid>
            {/* <Grid item xs={12} container>
              <Box height={"20px"}></Box>
            </Grid> */}

            <Grid item xs={12} container p="16px">
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                // alignItems="stretch"
                // border={1}

                // maxWidth={maxWidthCard}
              >
                <Grid item xs={4}>
                  <Typography
                    sx={{
                      color: Colors.textGrey,
                      // pb: { xs: "23px", md: "0" },
                      //   pl: { xs: "22px", lg: "71px" },
                      //   width: { xs: "213px", lg: "356px" },
                      fontSize: fontSizeTitle,
                      lineHeight: lineHeightTitle,
                      fontFamily: FontFamilyName.BasisGrotesquePro,
                      fontWeight: 400,

                      //   maxWidth: { xs: "150px", lg: "262px" },
                    }}
                  >
                    {title}
                  </Typography>
                </Grid>
                <Grid container item xs={8}>
                  <Grid
                    container
                    direction={"row"}
                    justifyContent="flex-end"
                    // border={1}
                    alignItems="center"
                  >
                    {isNew ? (
                      <Grid item mr={"10px"}>
                        <ZuluButton
                          onClick={link}
                          color={"info"}
                          height={{ xs: "25px", lg: "31px" }}
                          width={{ xs: "96px", lg: "132px" }}
                        >
                          <Typography
                            sx={{
                              fontFamily: FontFamilyName.Akkurat,
                              fontSize: { xs: "11px", lg: "16px" },
                              fontWeight: 400,
                              //   px: { xs: "5px", lg: "20px" },
                              //   py: { xs: "0px", lg: "10px" },
                              color: Colors.textGrey,
                              // lineHeight: { xs: "13.94px", md: "21px" },
                            }}
                          >
                            new product
                          </Typography>
                        </ZuluButton>
                      </Grid>
                    ) : (
                      <Grid item />
                    )}
                    <Grid item>
                      <CircleItem
                        onClick={() => {}}
                        color={"info"}
                        // width={{ xs: "30px" }}
                        // height={{ xs: "25px", lg: "31px" }}
                      >
                        <Typography
                          sx={{
                            fontFamily: FontFamilyName.Akkurat,
                            fontSize: { xs: "11px", lg: "16px" },
                            fontWeight: 400,
                            // lineHeight: { xs: "11px", lg: "25px" },
                            // px: { xs: "0px", lg: "20px" },
                            // py: { xs: "0px", lg: "10px" },
                            color: Colors.textGrey,
                            // border: 1,
                          }}
                        >
                          {year.toString()}
                        </Typography>
                      </CircleItem>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardActionArea>
      </Card>
      {/* <Box
        sx={{
          // pr: { xs: "10px", lg: "30px" },
          pb: { xs: "35px", lg: "102px" },
        }}
      > */}
      {/* <Card
        elevation={0}
        sx={{
          maxWidth: { maxWidthCard },
          maxHeight: { maxHeightCard },
          // backgroundColor: Colors.primaryRed,
          // pr: { xs: "10px", lg: "30px" },
        }}
        onClick={link}
      >
        <CardActionArea>
          <CardMedia
            sx={{
              maxHeight: heightImage,
              maxWidth: widthImage,
            }}
            // component="img"
            // image={ZuluConfig.baseUrlImage + image.baseUrl}
            // alt={image.alt}
            // {...imageSize?.imageProps}
            // sx={{
            //   // objectFit: "contain",
            //   // backgroundRepeat: "no-repeat",
            //   // maxHeight: maxHeightCard,
            //   // maxWidth: "100%",
            //   // width: "100%",
            //   // maxHeight: heightImage,
            //   // { xs: "207.37px", lg: heightImage },
            //   objectFit: "cover",
            //   backgroundRepeat: "no-repeat",
            //   // width: "100vw",
            // }}
            // title={image.alt}
          >
            <Box
              // width={"100%"}
              // "100%"
              // height={"100%"}
              // {{ xs: "207.37px", lg: "300px" }}
              justifyContent="center"
              alignItems="center"
              display={"flex"}
              border={1}
            >
              <Box
                {...imageSize?.imageProps}
                // width={"100%"}
                // height={"auto"}
                // maxHeight={"100%"}
                // {...srcset(ZuluConfig.baseUrlImage + image.baseUrl)}
                // object-position: 100% 0;
                component={"img"}
                alt={image.alt}
                src={ZuluConfig.baseUrlImage + image.baseUrl}
                // display={"flex"}
                sx={{
                  // objectPosition: "100% 0;",
                  objectFit: "cover",
                }}
              />
            </Box>
          </CardMedia>
          <CardContent>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="stretch"
              // border={1}

              // maxWidth={maxWidthCard}
            >
              <Grid item xs={4}>
                <Typography
                  sx={{
                    color: Colors.textGrey,
                    // pb: { xs: "23px", md: "0" },
                    //   pl: { xs: "22px", lg: "71px" },
                    //   width: { xs: "213px", lg: "356px" },
                    fontSize: fontSizeTitle,
                    lineHeight: lineHeightTitle,
                    fontFamily: FontFamilyName.BasisGrotesquePro,
                    fontWeight: 400,

                    //   maxWidth: { xs: "150px", lg: "262px" },
                  }}
                >
                  {title}
                </Typography>
              </Grid>
              <Grid container item xs={8}>
                <Grid
                  container
                  direction={"row"}
                  justifyContent="flex-end"
                  // border={1}
                  alignItems="center"
                >
                  {isNew ? (
                    <Grid item mr={"10px"}>
                      <ZuluButton
                        onClick={link}
                        color={"info"}
                        height={{ xs: "25px", lg: "31px" }}
                        width={{ xs: "96px", lg: "132px" }}
                      >
                        <Typography
                          sx={{
                            fontFamily: FontFamilyName.Akkurat,
                            fontSize: { xs: "11px", lg: "16px" },
                            fontWeight: 400,
                            //   px: { xs: "5px", lg: "20px" },
                            //   py: { xs: "0px", lg: "10px" },
                            color: Colors.textGrey,
                            // lineHeight: { xs: "13.94px", md: "21px" },
                          }}
                        >
                          new product
                        </Typography>
                      </ZuluButton>
                    </Grid>
                  ) : (
                    <Grid item />
                  )}
                  <Grid item>
                    <ZuluButton
                      onClick={link}
                      color={"info"}
                      // width={{ xs: "25px" }}
                      height={{ xs: "25px", lg: "31px" }}
                    >
                      <Typography
                        sx={{
                          fontFamily: FontFamilyName.Akkurat,
                          fontSize: { xs: "11px", lg: "16px" },
                          fontWeight: 400,
                          // px: { xs: "0px", lg: "20px" },
                          // py: { xs: "0px", lg: "10px" },
                          color: Colors.textGrey,
                          // border: 1,
                        }}
                      >
                        {year.toString()}
                      </Typography>
                    </ZuluButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card> */}
      {/* </Box> */}
    </>
  );
};

CardProduct.prototype = {
  link: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  year: PropTypes.number.isRequired,
  isNew: PropTypes.bool.isRequired,
  maxWidthCard: PropTypes.string.isRequired,
  maxHeightCard: PropTypes.string.isRequired,
  heightImage: PropTypes.number.isRequired,
  widthImage: PropTypes.number.isRequired,
  fontSizeTitle: PropTypes.any.isRequired,
  lineHeightTitle: PropTypes.any.isRequired,
  image: PropTypes.objectOf<ImagesType>,
};

export default CardProduct;
