import { Masonry } from "@mui/lab";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import CardTeam from "common/components/cardTeam";
import SizeBoxHideonxs from "common/components/sizebox";
import ZuluConfig from "common/config/zulu_config";
import Colors from "common/const/colorzulu";
import FontFamilyName from "common/const/fontfamily";
import ImageAssets from "common/const/imageassets";
import { AboutInterface } from "model/interfaces/about";
import { ImagesType } from "model/types/imagesType";
import OurTeam from "module/landingpage/components/ourteam";
import { useEffect } from "react";

const BodyAbout = (props: { data?: AboutInterface | null }) => {
  const { data } = props;
  const dataImageBody1 =
    data?.imageBody != null && data.imageBody.length > 0
      ? data?.imageBody[0]
      : null;
  const dataImageBody2 =
    data?.imageBody != null && data.imageBody.length > 1
      ? data?.imageBody[1]
      : null;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const MasonryView = () => {
    useEffect(() => {
      window.addEventListener("error", (e) => {
        // console.log(e);
        if (
          e.message ===
            "ResizeObserver loop completed with undelivered notifications." ||
          e.message === "ResizeObserver loop limit exceeded"
        ) {
          const resizeObserverErrDiv = document.getElementById(
            "webpack-dev-server-client-overlay-div"
          );
          const resizeObserverErr = document.getElementById(
            "webpack-dev-server-client-overlay"
          );
          if (resizeObserverErr) {
            resizeObserverErr.setAttribute("style", "display: none");
          }
          if (resizeObserverErrDiv) {
            resizeObserverErrDiv.setAttribute("style", "display: none");
          }
        }
      });
    }, []);

    return (
      <Masonry
        columns={{ xs: 1, lg: 2 }}
        spacing={{ xs: 0, lg: 5 }}
        // sx={{ backgroundColor: Colors.primaryRed }}
      >
        <Box
        // sx={{ backgroundColor: Colors.primaryRed }}
        >
          <Box
            component={"img"}
            src={
              dataImageBody1 != null
                ? ZuluConfig.baseUrlImage + dataImageBody1?.baseUrl
                : ImageAssets.sampleBodyProductDetail
            }
            alt={dataImageBody1?.alt ?? "About Zulu"}
            sx={{
              objectFit: "cover",
              backgroundRepeat: "no-repeat",
              height: { xs: "auto", md: "600px" },
              width: "100%",
              // pr: { xs: "10px", lg: "20px" },
              // backgroundColor: Colors.primaryRed,
            }}
          />
        </Box>

        <SizeBoxHideonxs
          height={"317px"}
          isMobile={isMobile}
          // color={Colors.primaryRed}
        />
        <SizeBoxHideonxs
          height={"5px"}
          isMobile={!isMobile}
          // color={Colors.primaryRed}
        />
        <Box
        // border={1}
        // sx={{ backgroundColor: Colors.primaryRed }}
        >
          <Box
            component={"img"}
            src={
              dataImageBody2 != null
                ? ZuluConfig.baseUrlImage + dataImageBody2?.baseUrl
                : ImageAssets.sampleAbout
            }
            alt={dataImageBody2?.alt ?? "About Zulu"}
            // src={ImageAssets.sampleAbout}
            sx={{
              objectFit: "cover",
              backgroundRepeat: "no-repeat",
              // maxWidth: { xs: "89vw", md: "100%" },
              height: { xs: "auto", md: "600px" },
              width: "100%",
              // width: { xs: "89vw", md: "100%" },
              // pr: { xs: "0px", lg: "20px" },
              // backgroundColor: Colors.primaryRed,
            }}
          />
        </Box>

        <SizeBoxHideonxs height={"80px"} isMobile={isMobile} />
        <Box>
          <Typography
            sx={{
              color: Colors.textGrey,
              fontFamily: FontFamilyName.Akkurat,
              fontSize: { xs: "12px", lg: "20px" },
              fontWeight: 400,
              whiteSpace: "pre-wrap",
              lineHeight: { xs: "18px", lg: "30px" },
              // width: { xs: "none", lg: "438px" },
            }}
          >
            {data?.desc2 ?? ``}
          </Typography>
        </Box>
      </Masonry>
    );
  };

  return (
    <Box sx={{ px: { xs: "21px", lg: "72px" } }}>
      <Box
      // border={1}
      >
        <Box height={{ xs: "39px", lg: "142px" }} />
        <Typography
          sx={{
            color: Colors.textGrey,
            fontFamily: FontFamilyName.BasisGrotesquePro,
            fontSize: { xs: "50px", md: "100px" },
            fontWeight: 500,
            whiteSpace: "pre-wrap",
            lineHeight: { xs: "50px", md: "100px" },
          }}
        >
          {data?.tagline ?? "Turning Vision\nInto Reality"}
        </Typography>
        <Box height={{ xs: "25px", lg: "40px" }} />
        <Box
          component={"img"}
          src={
            data?.imageCover?.baseUrl != null
              ? ZuluConfig.baseUrlImage + data?.imageCover?.baseUrl
              : ImageAssets.BackgroundMain
          }
          alt={data?.imageCover?.alt ?? "About Zulu"}
          sx={{
            objectFit: "cover",
            backgroundRepeat: "no-repeat",
            height: { xs: "auto", md: "600px" },
            width: "100%",
            // pr: { xs: "10px", lg: "20px" },

            // backgroundColor: Colors.primaryRed,
          }}
        />

        <Box height={{ xs: "15px", lg: "80px" }} />

        <Typography
          sx={{
            color: Colors.textGrey,
            fontFamily: FontFamilyName.BasisGrotesquePro,
            fontSize: { xs: "20px", lg: "40px" },
            fontWeight: 400,
            lineHeight: { xs: "28px", md: "52px" },
            whiteSpace: "pre-wrap",
          }}
        >
          {data?.desc1 ?? ``}
        </Typography>

        <Box height={{ xs: "20px", lg: "60px" }} />
        <MasonryView />
      </Box>
    </Box>
  );
};

export default BodyAbout;
