import { ApiConfigInterface } from "model/interfaces/api_config";
import AxiosApi from "./axios";

export class ApiController {
  static getAllProduct = async () => {
    let configAxios: ApiConfigInterface = {
      method: "get",
      path: "/v1/product",
    };

    let respon = await AxiosApi.call(configAxios);
    if (respon?.success) {
      return respon?.result.response;
    } else {
      return respon;
    }
  };

  static getSelectedProduct = async (path: string) => {
    let configAxios: ApiConfigInterface = {
      method: "get",
      path: "/v1/product/" + path,
    };

    let respon = await AxiosApi.call(configAxios);
    if (respon?.success) {
      return respon?.result;
    } else {
      return respon;
    }
  };

  static getDiscoverProduct = async (path: string) => {
    let configAxios: ApiConfigInterface = {
      method: "get",
      path: "/v1/product/" + path + "/discover",
    };

    let respon = await AxiosApi.call(configAxios);
    if (respon?.success) {
      return respon?.result?.response;
    } else {
      return respon;
    }
  };
  static getAboutProduct = async () => {
    let configAxios: ApiConfigInterface = {
      method: "get",
      path: "/v1/product/data/about",
    };

    let respon = await AxiosApi.call(configAxios);
    if (respon?.success) {
      return respon?.result;
    } else {
      return respon;
    }
  };

  static getAllProject = async () => {
    let configAxios: ApiConfigInterface = {
      method: "get",
      path: "/v1/project",
    };

    let respon = await AxiosApi.call(configAxios);
    if (respon?.success) {
      return respon?.result.response;
    } else {
      return respon;
    }
  };

  static getSelectedProject = async (path: string) => {
    let configAxios: ApiConfigInterface = {
      method: "get",
      path: "/v1/project/" + path,
    };

    let respon = await AxiosApi.call(configAxios);
    if (respon?.success) {
      return respon?.result;
    } else {
      return respon;
    }
  };

  static getDiscoverProject = async (path: string) => {
    let configAxios: ApiConfigInterface = {
      method: "get",
      path: "/v1/project/" + path + "/discover",
    };

    let respon = await AxiosApi.call(configAxios);
    if (respon?.success) {
      return respon?.result?.response;
    } else {
      return respon;
    }
  };

  static getAboutProject = async () => {
    let configAxios: ApiConfigInterface = {
      method: "get",
      path: "/v1/project/data/about",
    };

    let respon = await AxiosApi.call(configAxios);
    if (respon?.success) {
      return respon?.result;
    } else {
      return respon;
    }
  };

  static getContact = async () => {
    let configAxios: ApiConfigInterface = {
      method: "get",
      path: "/v1/contact/person",
    };

    let respon = await AxiosApi.call(configAxios);
    if (respon?.success) {
      return respon?.result;
    } else {
      return respon;
    }
  };

  static getAllService = async () => {
    let configAxios: ApiConfigInterface = {
      method: "get",
      path: "/v1/service",
    };

    let respon = await AxiosApi.call(configAxios);
    if (respon?.success) {
      return respon?.result.response;
    } else {
      return respon;
    }
  };

  static getAbout = async () => {
    let configAxios: ApiConfigInterface = {
      method: "get",
      path: "/v1/about",
    };

    let respon = await AxiosApi.call(configAxios);
    if (respon?.success) {
      return respon?.result;
    } else {
      return respon;
    }
  };

  static getAboutLandingPage = async () => {
    let configAxios: ApiConfigInterface = {
      method: "get",
      path: "/v1/about/landingpage",
    };

    let respon = await AxiosApi.call(configAxios);
    if (respon?.success) {
      return respon?.result;
    } else {
      return respon;
    }
  };

  static getAllCover = async () => {
    let configAxios: ApiConfigInterface = {
      method: "get",
      path: "/v1/cover",
    };

    let respon = await AxiosApi.call(configAxios);
    if (respon?.success) {
      return respon?.result.response;
    } else {
      return respon;
    }
  };

  static getAllClient = async () => {
    let configAxios: ApiConfigInterface = {
      method: "get",
      path: "/v1/client",
    };

    let respon = await AxiosApi.call(configAxios);
    if (respon?.success) {
      return respon?.result.response;
    } else {
      return respon;
    }
  };
  static getClientAbout = async () => {
    let configAxios: ApiConfigInterface = {
      method: "get",
      path: "/v1/client/data/about",
    };

    let respon = await AxiosApi.call(configAxios);
    if (respon?.success) {
      return respon?.result;
    } else {
      return respon;
    }
  };
}
