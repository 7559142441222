import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import fontFamily from "./theme-font";
import Colors from "common/const/colorzulu";
import { FontFamilyName } from "common/const/const_config";

// A custom theme for this app
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1440,
    },
  },
  typography: {
    fontFamily: [FontFamilyName.Akkurat, FontFamilyName.BasisGrotesquePro].join(
      ","
    ),
    button: {
      textTransform: "none",
    },
    // caption: {
    //   fontSize: "72px", lineHeight: "108px", fontWeight: 500
    // },
    // h1: {
    //   fontSize: "56px",lineHeight:"84px",fontWeight:500
    // },
    // h2: {
    //   fontSize: "48px",lineHeight:"72px",fontWeight:400
    // },
    // h3: {
    //   fontSize: "40px",lineHeight:"60px",fontWeight:400
    // },
    // h4: {
    //   fontSize: "32px",lineHeight:"48px",fontWeight:400
    // },

    // body1: {
    //   fontSize: "24px",lineHeight:"36px",fontWeight:400
    // },
    // body2: {
    //   fontSize: "20px",lineHeight:"30px",fontWeight:400
    // },

    // subtitle1: {
    //   fontSize: "16px",lineHeight:"24px",fontWeight:400
    // },
    // subtitle2: {
    //   fontSize: "12px",lineHeight:"18px",fontWeight:400
    // }
  },
  palette: {
    primary: {
      main: Colors.primaryRed,
    },
    secondary: {
      main: Colors.secondayWhite,
    },
    info: {
      main: Colors.infoBlack,
    },
    error: {
      main: Colors.primaryRed,
    },
    warning: {
      main: Colors.textHint,
    },
  },
  // typography: {
  //   fontFamily: [FontFamilyName.Akkurat, FontFamilyName.BasisGrotesquePro].join(
  //     ","
  // ),
  // },
  components: {
    MuiCssBaseline: {
      // styleOverrides: `
      //       // ${fontFamily.akkurat}
      //       // ${fontFamily.basislight}
      //       // ${fontFamily.basisReguler}
      //       // ${fontFamily.basisMedium}
      //       // ${fontFamily.basisBold}
      // `,
      // @font-face {
      //   font-family: '${FontFamilyName.BasisGrotesquePro}';
      //   font-style: normal;
      //   font-display: swap;
      //   font-weight: 400;
      //   src: local('${FontFamilyName.BasisGrotesquePro}'),local('${FontFamilyName.BasisGrotesquePro}-Reguler'),url('${basisReguler}') format('ttf');
      //   unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
      // }
      // ${ fontFamily.basisReguler }
      // styleOverrides: `@font-face: [
      //   ${fontFamily.basisMedium},
      //   ${fontFamily.akkurat}
      // ]`,
      // fontFamily.akkurat,
      // fontFamily.basisItalic,
      // fontFamily.basisMediumitalic,
      // fontFamily.basisReguler,
      // fontFamily.basislight,
      // fontFamily.basislightItalic,
      //     `
      //   @font-face {
      //     font-family: '${FontFamilyName.BasisGrotesquePro}';
      //     font-style: normal;
      //     font-display: swap;
      //     font-weight: 500;
      //     src: local('${FontFamilyName.BasisGrotesquePro}'), local('${FontFamilyName.BasisGrotesquePro}-Medium'), url(${BasisMedium}) format('ttf');
      //     unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
      //   }
      // `,
      // `"@font-face": fontFamily.basisMedium`
      // fontFamily.akkurat,
      // fontFamily.basisItalic,
      // fontFamily.basisMediumitalic,
      // fontFamily.basisReguler,
      // fontFamily.basislight,
      // fontFamily.basislightItalic,
    },
  },
});

export default theme;
