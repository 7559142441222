import {
  Box,
  Divider,
  Grid,
  Typography,
  Link,
  Paper,
  IconButton,
} from "@mui/material";
import ZuluButton from "common/components/zulubutton";
import ZuluConfig from "common/config/zulu_config";
import {
  Colors,
  FontFamilyName,
  ImageAssets,
  ZuluLink,
} from "common/const/const_config";
import { NavLink, useLocation } from "react-router-dom";
import { routes } from "routes";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useEffect, useState } from "react";
import { AboutInterface } from "model/interfaces/about";
import { ApiController } from "common/api/api_controller";
import { ContactPersonInterface } from "model/interfaces/contact_person";

const Footer = () => {
  const [data, setData] = useState<ContactPersonInterface | null>(null);
  const [isLoading, setLoading] = useState(false);

  async function getData() {
    setLoading(true);
    let respon = await ApiController.getContact();

    setData({ ...respon });
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setLoading(false);
    // console.log(data);
  }, [data]);

  function twiterClick() {
    ZuluLink.openlink(data?.linktwitter ? data?.linktwitter : ZuluLink.twitter);
  }
  function igClick() {
    ZuluLink.openlink(data?.linkIg ? data?.linkIg : ZuluLink.ig);
  }
  function LinkedinClick() {
    ZuluLink.openlink(data?.linkLinked ? data?.linkLinked : ZuluLink.linkedIn);
  }

  function shopBtnClick() {
    ZuluLink.openlink(
      data?.linkTokopedia ? data?.linkTokopedia : ZuluLink.tokopedia
    );
  }

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
           in place of 'smooth' */
    });
  }
  const BackToTop = () => {
    return (
      <Box pl={{ xs: "21px", md: "71px" }}>
        <Typography
          onClick={scrollToTop}
          sx={{
            fontFamily: FontFamilyName.Akkurat,
            fontSize: "16px",
            fontWeight: 400,
            color: Colors.textGrey,
            // borderBottom: 1,
            borderColor: Colors.textGrey,
            textDecoration: "underline",
            textUnderlineOffset: "3px",
            cursor: "pointer",
            // px: { xs: "18.5px", lg: "62.5px" },
            // py: { xs: "0px", lg: "14.5px" },
          }}
        >
          Back To Top
        </Typography>
      </Box>
    );
  };

  const AllRightReserved = () => {
    return (
      <Box>
        <Typography
          component={"span"}
          // style={{ display: "inline-block" }}
          sx={{
            // border: 1,
            fontFamily: FontFamilyName.Akkurat,
            fontSize: { xs: "8px", lg: "12px" },
            fontWeight: 400,
            color: Colors.textGrey,
            width: { xs: "300px", lg: "280px" },
            whiteSpace: "pre-wrap",
            lineHeight: { xs: "10px", lg: "15px" },
            mt: { xs: "none", lg: "10px" },
            // mt: { xs: "0px", lg: "62.5px" },
            // px: { xs: "18.5px", lg: "62.5px" },
            // py: { xs: "0px", lg: "14.5px" },
          }}
        >
          <Box>
            {`© 2023 Zulu. All rights reserved. Privacy Policy\nCCPA - We do not sell your personal information.`}
          </Box>
        </Typography>
      </Box>
    );
  };

  const IconFooter = () => {
    return (
      <Grid
        container
        columnSpacing={{ xs: 2, lg: "56px" }}
        direction={"row"}
        // justifyContent="center"
        // alignItems="center"
        mt={{ xs: "none", lg: "10px" }}
        // border={1}
      >
        <Grid container item xs={4}>
          {/* <Box
            onClick={twiterClick}
            component="img"
            alt="twitter"
            src={ImageAssets.Twitter}
            sx={{
              visibility: "true",
              width: "17px",
              textDecoration: "none",
              cursor: "pointer",
            }}
          /> */}
        </Grid>
        <Grid container item xs={4}>
          <Box
            onClick={igClick}
            component="img"
            alt="ig"
            src={ImageAssets.IG}
            sx={{
              width: "17px",
              textDecoration: "none",
              cursor: "pointer",
            }}
          />
        </Grid>
        <Grid container item xs={4}>
          <Box
            onClick={LinkedinClick}
            component="img"
            alt="ig"
            src={ImageAssets.LinkedIn}
            sx={{
              width: "17px",
              textDecoration: "none",
              cursor: "pointer",
            }}
          >
            {/* <LinkedInIcon color={"warning"} fontSize="small" /> */}
          </Box>

          {/* <Box
          onClick={LinkedinClick}
          component="img"
          alt="spotify"
          src={ImageAssets.Spotify}
          sx={{
            width: "17px",
            textDecoration: "none",
            cursor: "pointer",
          }}
        /> */}
        </Grid>
      </Grid>
    );
  };

  const MenuNavbarWithBtnShop = () => {
    return (
      <Grid
        container
        direction={"row"}
        justifyContent={{ xs: "flex-end", md: "space-between" }}
        pt={{ xs: "0px", lg: "10px" }}
        // alignItems="stretch"
        pr={{ xs: "21px", lg: "0px" }}
        // columnSpacing={10}
        // border={1}
      >
        <Grid
          item
          xs={6}
          // border={1}
        >
          <Grid
            container
            direction={"row"}
            rowSpacing={{ xs: 0, lg: 0.5 }}
            columnSpacing={{ xs: 4, lg: 4 }}
            justifyContent={{ xs: "flext-start" }}
            alignContent={"flex-start"}
            // mt={}
          >
            {routes.map((item, index) =>
              item.enabled ? (
                <Grid
                  container
                  item
                  xs={6}
                  key={item.key}
                  // border={1}
                  height={{ xs: "20px", md: "40px" }}
                >
                  <Link
                    component={NavLink}
                    to={item.path}
                    color="black"
                    underline="none"
                    variant="button"
                  >
                    <Typography
                      // onClick={props.function}
                      sx={{
                        fontFamily: FontFamilyName.Akkurat,
                        fontSize: { xs: "12px", lg: "24px" },
                        fontWeight: 400,
                        textDecoration: "none",
                        cursor: "pointer",
                        color: Colors.textGrey,
                        mt: { xs: "-8px", md: "none" },
                        // px: { xs: "18.5px", lg: "62.5px" },
                        // py: { xs: "0px", lg: "14.5px" },
                      }}
                    >
                      {item.title}
                    </Typography>
                  </Link>
                </Grid>
              ) : (
                <Box key={item.key} />
              )
            )}
          </Grid>
        </Grid>
        {/* <Grid
        item
        sx={{ display: { xs: "none", md: "block" } }}
        border={1}
        // width={"112px"}
        md={3}
      ></Grid> */}
        <Grid
          item
          sx={{ display: { xs: "none", md: "block" } }}
          // border={1}
        >
          <ShopBtn />
        </Grid>
      </Grid>
    );
  };

  const ShopBtn = () => {
    return (
      <ZuluButton
        onClick={shopBtnClick}
        color={"info"}
        width={{ xs: "64px", lg: "163px" }}
        height={{ xs: "20px", lg: "50px" }}
      >
        <Typography
          sx={{
            fontFamily: FontFamilyName.Akkurat,
            fontSize: { xs: "11px", lg: "16px" },
            fontWeight: 400,
            // px: { xs: "10px", lg: "50px" },
            // py: { xs: "5px", lg: "10px" },
            color: Colors.textGrey,
          }}
        >
          Shop
        </Typography>
      </ZuluButton>
    );
  };

  return (
    <>
      {/* <Box pl={"21px"}> */}
      {/* <Paper
       sx={{
         border: "none",
          marginTop: "calc(10% + 60px)",
         position: "fixed",
         bottom: 0,
         width: "100%",
       }}
       component="footer"
       square
       variant="outlined"
     > */}
      {/* sx={{ position: "fixed", bottom: 0, width: "100%" }} */}
      <Divider variant="fullWidth" orientation="horizontal" />
      <Box
        sx={{
          my: {
            xs: "28px",
            lg: "19px",
          },
          ml: {
            xs: "21px",
            md: "71px",
          },
          mr: {
            xs: "21px",
            md: "49px",
          },
          // backgroundColor: Colors.infoBlack,
        }}
      >
        <Grid container direction={"row"} justifyContent="space-between">
          <Grid
            item
            xs={5}
            md={7.5}
            // border={1}
          >
            <Grid
              container
              direction={"column"}
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={"25.5px"}
              //   sx={{
              //     backgroundColor: Colors.infoBlack,
              //     width: "100%",
              //   }}
            >
              <Grid item>
                <Link component={NavLink} to="/">
                  <Box
                    component="img"
                    alt="zulu"
                    src={ImageAssets.MainLogo}
                    sx={{
                      pt: { xs: "0px", lg: "10px" },
                      // width: { xs: "64px", sm: "64px", md: "91px", xl: "91px" },
                      width: {
                        xs: "64px",
                        lg: "183px",
                      },
                    }}
                  />
                </Link>
              </Grid>
              <Grid
                item
                // xs={12}
                sx={{
                  display: { xs: "block", sm: "block", md: "none", lg: "none" },
                  pt: "25px",
                }}
              >
                <ShopBtn />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={7}
            md={4.5}
            // border={1}
          >
            <MenuNavbarWithBtnShop />
          </Grid>
        </Grid>

        <Typography
          sx={{
            fontFamily: FontFamilyName.BasisGrotesquePro,
            fontSize: { xs: "16px", lg: "48px" },
            fontWeight: 400,
            color: Colors.primaryRed,
            pt: { xs: "30px" },
            // px: { xs: "18.5px", lg: "62.5px" },
            // py: { xs: "0px", lg: "14.5px" },
          }}
        >
          Turning Vision Into Reality
        </Typography>
      </Box>
      <Divider variant="fullWidth" orientation="horizontal" />
      <Box
        sx={{
          mt: {
            xs: "28px",
            lg: "19px",
          },
          // mx: {
          //   xs: "22px",
          //   lg: "71px",
          // },
        }}
      >
        <Grid
          container
          spacing={2}
          direction={"row"}
          justifyContent="space-between"
          alignItems="flex-start"
          // border={1}
        >
          <Grid
            container
            item
            xs={6}
            sx={{
              display: { xs: "block", sm: "block", md: "none", lg: "none" },
            }}
            ml={{ xs: "21px", md: "71px" }}
            // border={1}
          >
            <AllRightReserved />
          </Grid>
          <Grid
            item
            xs={5}
            sx={{
              display: { xs: "block", sm: "block", md: "none", lg: "none" },
            }}
          >
            <IconFooter />
          </Grid>
          <Grid item sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
            <BackToTop />
          </Grid>
          <Grid item sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
            <IconFooter />
          </Grid>
          <Grid
            item
            sx={{ display: { xs: "none", sm: "none", md: "block" } }}
            md={4.63}
            lg={ZuluConfig.withMaxWidth ? 4.59 : 4.5}
          >
            <AllRightReserved />
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          height: { xs: "14px", lg: "38px" },
        }}
      />
      {/* </Box> */}
    </>
    // </Paper>
  );
};
export default Footer;
