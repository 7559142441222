import { Box, Grid, Typography } from "@mui/material";
import { ApiController } from "common/api/api_controller";
import ZuluConfig from "common/config/zulu_config";
import Colors from "common/const/colorzulu";
import {
  ImageAssets,
  FontFamilyName,
  ZuluLink,
} from "common/const/const_config";
import { AboutPageInterface } from "model/interfaces/about_page";
import { ClientInterface } from "model/interfaces/client";
import { useState, useEffect } from "react";

const ClientAbout = () => {
  const [data, setData] = useState<ClientInterface[] | null>(null);
  const [dataAbout, setDataAbout] = useState<AboutPageInterface | null>(null);
  const [isLoading, setLoading] = useState(false);

  async function getData() {
    setLoading(true);
    let respon = await ApiController.getAllClient();

    setData([...respon]);
  }

  async function getDataAbout() {
    setLoading(true);
    let respon = await ApiController.getClientAbout();

    setDataAbout({ ...respon });
  }

  useEffect(() => {
    getData();
    getDataAbout();
  }, []);

  useEffect(() => {
    setLoading(false);
    // console.log(data);
  }, [data]);

  const listIcon: string[] = [
    ImageAssets.gojek,
    ImageAssets.zulu,
    ImageAssets.nafas,
    ImageAssets.gojek,
    ImageAssets.zulu,
    ImageAssets.nafas,
    ImageAssets.gojek,
    ImageAssets.zulu,
  ];
  return (
    <>
      <Box
        sx={{
          height: { xs: "29px", md: "80px" },
        }}
      />

      <Grid
        container
        direction={"row"}
        px={{ xs: "21px", lg: "71px" }}
        spacing={{ xs: 3, lg: 10 }}
        //   rowSpacing={"149px"}
        //   columnSpacing={"210px"}
      >
        {/* <Grid item xs={12}>
          <Box height={{ lg: "5px" }}></Box>
        </Grid> */}
        <Grid item xs={12} lg={6}>
          <Typography
            sx={{
              color: Colors.textGrey,
              fontFamily: FontFamilyName.BasisGrotesquePro,
              fontSize: { xs: "24px", lg: "60px" },
              fontWeight: 400,

              mt: { lg: "20px" },
              // maxWidth: { xs: "none", lg: "535px" },

              //   backgroundColor: Colors.primaryRed,
            }}
          >
            Clients
          </Typography>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Typography
            sx={{
              color: Colors.textGrey,
              fontFamily: FontFamilyName.Akkurat,
              fontSize: { xs: "12px", lg: "24px" },
              fontWeight: 400,
              lineHeight: { xs: "18px", lg: "36px" },
              // maxWidth: { xs: "none", lg: "535px" },

              //   backgroundColor: Colors.primaryRed,
            }}
          >
            {dataAbout?.desc ?? ``}
          </Typography>
        </Grid>
        {data?.map((item, index) => (
          <Grid
            item
            container
            xs={6}
            md={3}
            key={index}
            alignContent="center"
            justifyContent="center"
            //   bgcolor={Colors.primaryRed}
            // border={1}
          >
            <Box
              component={"img"}
              alt={item?.name}
              src={ZuluConfig.baseUrlImage + item.logo?.baseUrl}
              onClick={() => ZuluLink.openlink(item?.url)}
              sx={{
                objectFit: "contain",
                backgroundRepeat: "no-repeat",
                width: { xs: "80%", md: "50%" },
                cursor: "pointer",
                //   height: "50px",
                //   border: 1,
                // height: { xs: "176px", lg: "600px" },
                //   width: "100%",
                // pr: { xs: "10px", lg: "20px" },
                // backgroundColor: Colors.primaryRed,
              }}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default ClientAbout;
