class Colors {
    static white = "#fff";
  static infoBlack = "#000";
  static primaryRed = "#BA1818";
  static secondayWhite = "#fff";
  static textGrey = '#414042';
  static textHint = '#ADACAF';

  }
  
  export default Colors;