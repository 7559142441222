import { Box } from "@mui/material";
import PropTypes from "prop-types";

const CircleItem = (props: any) => {
  const { onClick, borderColor, color, width, height, children } = props;

  return (
    <Box
      //   type="submit"
      //   variant="outlined"
      onClick={onClick}
      color={color ?? "primary"}
      sx={{
        borderRadius: "30px",
        borderColor: { borderColor } ?? "#000",
        width: width ?? "fit-content",
        height: height ?? "auto",
        border: 1,
        px: "10px",
      }}
    >
      {children}
    </Box>
  );
};

CircleItem.propTypes = {
  onClick: PropTypes.func,
  color: PropTypes.string,
  width: PropTypes.any,
  height: PropTypes.any,
  borderColor: PropTypes.string,
  children: PropTypes.any,
};
export default CircleItem;
