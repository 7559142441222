import axios from "axios";
import ZuluConfig from "common/config/zulu_config";
import { ApiConfigInterface } from "model/interfaces/api_config";
import { config } from "process";

class AxiosApi {
  static call = async (data: ApiConfigInterface) => {
    try {
      let headerListNonToken = {
        Accept: "*/*",
        "Content-Type": "application/json",
      };

      let reqOptions = {
        url: ZuluConfig.baseURLBE + data.path,
        method: data.method,
        headers: headerListNonToken,
        // headers: {},
        data: data?.body ?? undefined,
      };

      let response = await axios.request(reqOptions);

      // if (config.isDebug) {
      //   console.log('===============AXIOS REPO =====================');
      //   console.log(data);
      //   console.log(response.data);
      //   console.log('===============AXIOS REPO =====================');
      // }

      return response.data;
    } catch (error: any) {
      let responMessage = error?.response?.data?.message;
      // if (responMessage !== undefined && error.response.status === 401) {
      //   data?.dispatch({ type: toggleSnackbarOpen, message: responMessage, color: 'error' });
      // } else {
      //   data?.dispatch({ type: toggleSnackbarOpen, message: error.message, color: 'error' });
      //   if (config.isDebug) {
      //     console.log('===============AXIOS REPO ERROR =====================');
      //     console.error(error);
      //   }
      // }
      return error;
    }
  };
}

export default AxiosApi;
