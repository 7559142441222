import { Box, Container, Grid, Typography } from "@mui/material";
import { ApiController } from "common/api/api_controller";
import ZuluButton from "common/components/zulubutton";
import Colors from "common/const/colorzulu";
import FontFamilyName from "common/const/fontfamily";
import ImageAssets from "common/const/imageassets";
import ZuluLink from "common/const/link";
import { ContactPersonInterface } from "model/interfaces/contact_person";
import { useEffect, useState } from "react";

const OurShop = () => {
  const [data, setData] = useState<ContactPersonInterface | null>(null);
  const [isLoading, setLoading] = useState(false);

  async function getData() {
    setLoading(true);
    let respon = await ApiController.getContact();

    setData({ ...respon });
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setLoading(false);
    // console.log(data);
  }, [data]);
  return (
    <Box sx={{ px: { xs: "22px", lg: "71px" } }}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        // alignContent="center"
        spacing={2}
        // minWidth="100vw"
        sx={{
          // pb: { xs: "36px", lg: "56px" },
          pt: { xs: "23px", lg: "46px" },
          // px: { xs: "22px", lg: "71px" },
          //   backgroundColor: Colors.primaryRed,
        }}
      >
        <Grid item xs={12} lg={3}>
          <Typography
            sx={{
              fontFamily: FontFamilyName.BasisGrotesquePro,
              fontSize: { xs: "24px", lg: "32px" },
              fontWeight: 400,
              //   px: { xs: "22.5px", lg: "62.5px" },
              //   py: { xs: "5.5px", lg: "14.5px" },
              color: Colors.textGrey,
            }}
          >
            Shop our creation at
          </Typography>
        </Grid>

        <Grid item xs={4} lg={3} textAlign={"center"}>
          <Box
            component={"img"}
            src={ImageAssets.lazada}
            onClick={() =>
              ZuluLink.openlink(
                data?.linkLazada ? data?.linkLazada : ZuluLink.lazada
              )
            }
            sx={{
              width: { xs: "85.43px", lg: "162px" },
              textDecoration: "none",
              cursor: "pointer",
            }}
          />
        </Grid>
        <Grid item xs={4} lg={3} textAlign={"center"}>
          <Box
            component={"img"}
            src={ImageAssets.tokopedia}
            onClick={() =>
              ZuluLink.openlink(
                data?.linkTokopedia ? data?.linkTokopedia : ZuluLink.tokopedia
              )
            }
            sx={{
              width: { xs: "86px", lg: "150px" },
              textDecoration: "none",
              cursor: "pointer",
            }}
          />
        </Grid>
        <Grid item xs={4} lg={3} textAlign={"center"}>
          <Box
            component={"img"}
            src={ImageAssets.shopee}
            onClick={() =>
              ZuluLink.openlink(
                data?.linkShopee ? data?.linkShopee : ZuluLink.shopee
              )
            }
            sx={{
              width: { xs: "85px", lg: "150px" },
              textDecoration: "none",
              cursor: "pointer",
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default OurShop;
