import { Box, Grid, Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import SpaceingAppBar from "common/components/spacingappbar";
import Footer from "common/footer/footer";
import Header from "common/header/header";
import ProductHeader from "./components/productHeader";
import ProductGrid from "./components/productGrid";
import productDummy from "dummy/dataProduct";
import ZuluButton from "common/components/zulubutton";
import { Colors, FontFamilyName } from "common/const/const_config";
import OurShop from "./components/ourshop";
import { slice } from "lodash";
import { ProductInterface } from "model/interfaces/product";
import { useState, useEffect } from "react";
import { ApiController } from "common/api/api_controller";
import { AboutPageInterface } from "model/interfaces/about_page";

const Product = () => {
  const [data, setData] = useState<ProductInterface[]>([]);
  const [index, setIndex] = useState(10);
  const initialData = slice(data, 0, index);
  const [isCompleted, setCompleted] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [dataAbout, setDataAbout] = useState<AboutPageInterface | null>(null);

  const loadMore = () => {
    if (!isCompleted) {
      setLoading(true);
      setIndex(index + 10);
      // console.log(index);

      if (index + 10 >= data.length) {
        setCompleted(true);
      } else {
        setCompleted(false);
      }
      setLoading(false);
    }
  };

  async function getData() {
    setLoading(true);
    let respon = await ApiController.getAllProduct();

    if (index >= respon.length) {
      setCompleted(true);
    } else {
      setCompleted(false);
    }
    // console.log(respon);
    setData([...respon]);
  }
  async function getDataAbout() {
    setLoading(true);
    let respon = await ApiController.getAboutProduct();

    setDataAbout({ ...respon });
  }

  useEffect(() => {
    getData();
    getDataAbout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [data]);
  return (
    <>
      {/* <Header />
      <SpaceingAppBar /> */}

      <Box sx={{ height: { xs: "40px", lg: "142px" } }} />
      <ProductHeader dataAbout={dataAbout} />
      <Box sx={{ height: { xs: "43px", lg: "103px" } }} />

      <ProductGrid allData={initialData} />

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          pb: { xs: "36px", lg: "56px" },
          pt: { xs: "23px", lg: "46px" },
          // backgroundColor: Colors.primaryRed,
        }}
      >
        {isCompleted ? (
          <></>
        ) : (
          <ZuluButton
            onClick={() => {
              loadMore();
              // alert("clicked");
            }}
            color={"info"}
            width={{ xs: "118px", lg: "232px" }}
            height={{ xs: "25px", lg: "50px" }}
          >
            <Typography
              sx={{
                fontFamily: FontFamilyName.Akkurat,
                fontSize: { xs: "11px", lg: "16px" },
                fontWeight: 400,
                // px: { xs: "22.5px", lg: "62.5px" },
                // py: { xs: "5.5px", lg: "14.5px" },

                // px: { xs: "20.5px", lg: "71.5px" },
                // py: { xs: "5.5px", lg: "14.5px" },
                color: Colors.textGrey,
              }}
            >
              Load More
            </Typography>
          </ZuluButton>
        )}
      </Grid>

      <Divider variant="fullWidth" orientation="horizontal" />

      <OurShop />

      {/* <Footer /> */}
    </>
  );
};

export default Product;
