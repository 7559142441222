import { Box, Container, Stack, Typography, Grid } from "@mui/material";
import ZuluButton from "common/components/zulubutton";
import { Colors, FontFamilyName, ImageAssets } from "common/const/const_config";
import { useNavigate } from "react-router-dom";

export default function Descpage(props: { desc: string | null }) {
  const { desc } = props;
  const navigate = useNavigate();
  return (
    <>
      <Grid container direction={"row"} justifyContent={"space-between"}>
        <Grid item width={{ xs: "90vw", lg: "60%" }}>
          <Grid container direction={"column"} pl={{ xs: "22px", lg: "71px" }}>
            <Grid item>
              <Box
                sx={{
                  pt: { xs: "49px", lg: "48px" },
                  // width: { xs: "279px", lg: "801px" },
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: "32px", lg: "48px" },
                    fontFamily: FontFamilyName.BasisGrotesquePro,
                    fontWeight: 400,
                    lineHeight: { xs: "41px", lg: "57px" },
                  }}
                >
                  {desc ?? ``}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box height={"40px"} />
            </Grid>

            <Grid item>
              <Box>
                <ZuluButton
                  onClick={() => {
                    navigate("/about");
                    // alert("clicked");
                  }}
                  color={"info"}
                  width={{ xs: "96px", md: "200px", lg: "200px" }}
                  height={{ xs: "25px", md: "50px", lg: "50px" }}
                >
                  <Typography
                    sx={{
                      fontFamily: FontFamilyName.Akkurat,
                      fontSize: { xs: "11px", lg: "16px" },
                      fontWeight: 400,
                      // mx: { xs: "5px", lg: "20px" },
                      // py: { xs: "5px", lg: "10px" },
                      lineHeight: { xs: "13.94px", lg: "21px" },
                      // border: 1,
                    }}
                  >
                    Read More
                  </Typography>
                </ZuluButton>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {/* display={{ xs: "none", md: "none", lg: "block" }} */}
        <Grid item display={{ xs: "none", lg: "block" }}>
          <Box height={{ md: "15vw", lg: "10vw" }} />

          {/* <Box
            component="img"
            alt="dummy1"
            src={ImageAssets.dummy1}
            sx={{
              maxWidth: "350px",
              maxHeight: "345px",
              objectFit: "cover",
              backgroundRepeat: "no-repeat",
              width: "30vw",
              mr: "5vw",
            }}
          /> */}
        </Grid>
      </Grid>
    </>
  );

  // return (
  //   <>
  //     <Stack
  //       sx={{
  //         zindex: 1,
  //       }}
  //       // direction="row"
  //     >
  //       <Box
  //         sx={{
  //           pt: { xs: "49px", lg: "48px" },
  //           pl: { xs: "22px", lg: "71px" },
  //           width: { xs: "279px", lg: "801px" },
  //         }}
  //         zIndex={1}
  //       >
  //         <Typography
  //           sx={{
  //             fontSize: { xs: "32px", lg: "48px" },
  //             fontFamily: FontFamilyName.BasisGrotesquePro,
  //             fontWeight: 400,
  //             lineHeight: "57px",
  //           }}
  //         >
  //           This is the brand statement Lorem ipsum dolor sit amet, consectetur
  //           adipiscing elit. Amet praesent amet amet, purus, non libero urna.
  //         </Typography>
  //       </Box>
  //       <Box height={"10px"} />

  //       <Box pt={"24px"} pl={{ xs: "22px", lg: "71px" }} zIndex={2}>
  //         <ZuluButton
  //           onClick={() => {
  //             // alert("clicked");
  //           }}
  //           color={"info"}
  //         >
  //           <Typography
  //             sx={{
  //               fontFamily: FontFamilyName.Akkurat,
  //               fontSize: { xs: "11px", lg: "16px" },
  //               fontWeight: 400,
  //               px: { xs: "20.5px", lg: "71.5px" },
  //               py: { xs: "5.5px", lg: "14.5px" },
  //             }}
  //           >
  //             Read More
  //           </Typography>
  //         </ZuluButton>
  //       </Box>
  //       <Box
  //         sx={{
  //           display: { xs: "none", lg: "block" },
  //           pr: "95px",
  //           position: "absolute",
  //           right: "0px",
  //           // top: "calc(100vh + 208px)",
  //         }}
  //       >
  //         <Box
  //           component="img"
  //           alt="dummy1"
  //           src={ImageAssets.dummy1}
  //           sx={{
  //             px: { xs: "6px", lg: "31px" },
  //             py: { xs: "10px", lg: "20px" },
  //           }}
  //         ></Box>
  //       </Box>
  //     </Stack>
  //   </>
  // );
}
