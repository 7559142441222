class ZuluLink {
  static baseUrl = "https://zulu.ale.my.id/";
  static shopee = "https://shopee.com";
  static tokopedia = "https://tokopedia.link/6LAGBwqMwzb";
  static lazada = "https://lazada.co.id";
  static ig = "https://www.instagram.com/ridezulu";
  static linkedIn = "https://www.linkedin.com/company/ridezulu";
  static twitter = "https://twitter.com";
  static wa = "https://wa.me/";
  static mail = "mailto:";

  static openlink(url: string) {
    if (url !== "") window.open(url, "_blank");
  }
}

export default ZuluLink;
