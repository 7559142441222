import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  IconButton,
  CircularProgress,
} from "@mui/material";
import Colors from "common/const/colorzulu";
import FontFamilyName from "common/const/fontfamily";
import ZuluArrowRight from "common/svg/rightIcon";
import { ImagesType } from "model/types/imagesType";
import ZuluButton from "./zulubutton";
import ImageAssets from "common/const/imageassets";
import ZuluConfig from "common/config/zulu_config";
import { getImageSize } from "react-image-size";
import { useState, useEffect } from "react";
import CircleItem from "./circle_item";

const CardDiscover = (props: {
  image: ImagesType[] | null;
  title: string;
  onTap: () => void;
  widthImage: number;
  heightImage: number;
  // enablePaddingRight: boolean;
}) => {
  const { image, title, onTap, widthImage, heightImage } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isLoading, setIsLoading] = useState(false);
  // console.log(allProject.length);
  const [imageSize, setImageSize] = useState({
    width: 0,
    height: 0,
    imageProps: {},
  });

  const runImageSize = async () => {
    setIsLoading(true);
    let imageSrc = encodeURI(ZuluConfig.baseUrlImage + image?.[0].baseUrl);
    let res = await getImageSize(imageSrc);
    let imageProps = {};
    let biggerWidthThanDefault = res.width > widthImage;
    let biggerHeightThanDefault = res.height > heightImage;
    // console.log(imageSrc);
    //islandscape
    if (res.width > res.height) {
      if (biggerWidthThanDefault) {
        imageProps = { width: `${widthImage}px`, height: "100%" };
      } else {
        console.log(heightImage - res.height);
        if (heightImage - res.height > 100)
          imageProps = {
            height: `${heightImage}px`,
            width: "100%",
          };
      }

      //ispotrait
    } else if (res.height > res.width) {
      if (biggerHeightThanDefault) {
        imageProps = { width: "100%", height: `${heightImage}px` };
      } else {
        if (widthImage - res.width < 100)
          imageProps = { width: `${widthImage}px` };
      }

      //issquare
    } else if (res.width === res.height) {
      if (biggerWidthThanDefault && biggerHeightThanDefault) {
        imageProps = { width: `${widthImage}px`, height: `${heightImage}px` };
      } else {
        imageProps = { width: "100%", height: "100%" };
      }
    }
    const _imageProps = { ...imageProps, src: imageSrc, alt: image?.[0].alt };

    setImageSize({ ...res, imageProps: _imageProps });
    setIsLoading(false);
  };

  useEffect(() => {
    runImageSize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);

  useEffect(() => {
    // console.log(imageSize);
    setIsLoading(false);
  }, [imageSize]);

  return (
    <Box
      sx={{
        pl: { xs: "0px", sm: "22px", md: "0px", lg: "0px" },
        pr: { xs: "22px", sm: "0px", md: "22px", lg: "22px" },
        // border: 1,
        // pb: { xs: "35px", lg: "102px" },
      }}
    >
      <Card elevation={0} onClick={onTap}>
        <CardActionArea>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            // maxWidth={{ xs: "auto", lg: maxWidthCard }}
            // maxHeight={{ xs: "auto", lg: maxHeightCard }}
            // spacing={1}
          >
            <Grid
              item
              xs={12}
              alignItems="stretch"
              // border={1}
              // width={`${widthImage}px`}
              // height={`${heightImage}px`}
              container
            >
              <Box
                width={`${widthImage}px`}
                // width={"100%"}
                // height={"100%"}
                // "100%"
                height={`${heightImage}px`}
                // {{ xs: "207.37px", lg: "300px" }}
                justifyContent="center"
                alignItems="center"
                display={"flex"}
                // border={1}
              >
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  <Box
                    {...imageSize?.imageProps}
                    // width={"100%"}
                    // height={"auto"}
                    // maxHeight={"100%"}
                    // {...srcset(ZuluConfig.baseUrlImage + image.baseUrl)}
                    // object-position: 100% 0;
                    component={"img"}
                    // alt={image?.[0]?.alt}
                    // src={ZuluConfig.baseUrlImage + image.baseUrl}
                    // display={"flex"}

                    sx={{
                      // objectPosition: " 50% 50%;",
                      objectFit: "cover",
                    }}
                  />
                )}
              </Box>
            </Grid>
            {/* <Grid item xs={12} container>
              <Box height={"20px"}></Box>
            </Grid> */}

            <Grid item xs={12} container p={{ xs: "11px 0px", md: "16px" }}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="start"
              >
                <Grid item xs={7} sm={9}>
                  <Typography
                    sx={{
                      color: Colors.textGrey,
                      // pb: { xs: "23px", md: "0" },
                      //   pl: { xs: "22px", lg: "71px" },
                      //   width: { xs: "213px", lg: "356px" },
                      fontSize: { xs: "24px", lg: "32px" },
                      lineHeight: { xs: "26px", lg: "48px" },
                      fontFamily: FontFamilyName.BasisGrotesquePro,
                      fontWeight: 400,
                      // width: { xs: "130px", lg: "254px" },
                    }}
                  >
                    {title}
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={3}>
                  <Grid
                    container
                    direction={"row"}
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Grid item>
                      {isMobile ? (
                        <IconButton onClick={onTap}>
                          <Box
                            component={"img"}
                            src={ImageAssets.arrowRightPH}
                          />
                        </IconButton>
                      ) : (
                        <Box pt={{ xs: "5px", lg: "10px" }}>
                          <ZuluButton
                            onClick={onTap}
                            color={"info"}
                            width={{ xs: "10px", lg: "32px" }}
                          >
                            <ZuluArrowRight
                              color={Colors.textGrey}
                              // width={"10"}
                              // height={isMobile ? "7" : "10"}
                            />
                            {/* <mobileArrowRight /> */}

                            {/* <Box
                      component="img"
                      alt="zulu"
                      style={{
                        fill: Colors.textGrey,
                      }}
                      sx={{
                        content: {
                          xs: `url(${ImageAssets.mobileArrowRight})`,
                          lg: `url(${ImageAssets.desktopArrowRight})`,
                        },
                        px: { xs: "0px", lg: "10px" },
                        py: { xs: "0px", lg: "5px" },
                        color: Colors.textGrey,
                      }}
                    /> */}
                          </ZuluButton>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* <Grid
                container
                direction="row"
                justifyContent="space-between"
                // alignItems="stretch"
                // border={1}

                // maxWidth={maxWidthCard}
              >
                <Grid item xs={4}>
                  <Typography
                    sx={{
                      color: Colors.textGrey,
                      // pb: { xs: "23px", md: "0" },
                      //   pl: { xs: "22px", lg: "71px" },
                      //   width: { xs: "213px", lg: "356px" },
                      fontSize: fontSizeTitle,
                      lineHeight: lineHeightTitle,
                      fontFamily: FontFamilyName.BasisGrotesquePro,
                      fontWeight: 400,

                      //   maxWidth: { xs: "150px", lg: "262px" },
                    }}
                  >
                    {title}
                  </Typography>
                </Grid>
                <Grid container item xs={8}>
                  <Grid
                    container
                    direction={"row"}
                    justifyContent="flex-end"
                    // border={1}
                    alignItems="center"
                  >
                    <Grid item>
                      <CircleItem
                        onClick={() => {}}
                        color={"info"}
                        // width={{ xs: "30px" }}
                        // height={{ xs: "25px", lg: "31px" }}
                      >
                        <Typography
                          sx={{
                            fontFamily: FontFamilyName.Akkurat,
                            fontSize: { xs: "11px", lg: "16px" },
                            fontWeight: 400,
                            // lineHeight: { xs: "11px", lg: "25px" },
                            // px: { xs: "0px", lg: "20px" },
                            // py: { xs: "0px", lg: "10px" },
                            color: Colors.textGrey,
                            // border: 1,
                          }}
                        >
                          {year.toString()}
                        </Typography>
                      </CircleItem>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid> */}
            </Grid>
          </Grid>
        </CardActionArea>
      </Card>
    </Box>
  );

  // return (
  //   <Box
  //     sx={{
  //       pl: { xs: "0px", sm: "22px", md: "0px", lg: "0px" },
  //       pr: { xs: "22px", sm: "0px", md: "22px", lg: "22px" },
  //       // border: 1,
  //       // pb: { xs: "35px", lg: "102px" },
  //     }}
  //   >
  //     <Card
  //       elevation={0}
  //       sx={{
  //         maxWidth: { xs: "100%", sm: "100%", md: "400px", lg: "400px" },
  //         // maxWidth: "50%",
  //         // maxHeight: "506px",
  //         // backgroundColor: Colors.primaryRed,
  //         // pr: { xs: "10px", lg: "30px" },
  //       }}
  //       onClick={props.onTap}
  //     >
  //       <CardActionArea>
  //         <CardMedia
  //           component="img"
  //           image={
  //             image != null ? ZuluConfig.baseUrlImage + image[0].baseUrl : ""
  //           }
  //           alt={image != null ? image[0].alt : "zulu discover"}
  //           {...imageSize.imageProps}
  //           sx={{
  //             // height: { xs: "129px", sm: "auto", md: "auto" },
  //             // width: "100%",
  //             objectFit: {
  //               xs: "cover",
  //               sm: "cover",
  //               md: "contain",
  //               lg: "contain",
  //             },
  //             backgroundRepeat: "no-repeat",
  //             // "345px"
  //             //   width: { xs: "215px", lg: "300px" },
  //           }}
  //         />
  //         <CardContent sx={{ pl: 0, pr: 0, pb: 0 }}>
  //           <Grid
  //             container
  //             direction="row"
  //             justifyContent="space-between"
  //             alignItems="start"
  //           >
  //             <Grid item xs={7} sm={9}>
  //               <Typography
  //                 sx={{
  //                   color: Colors.textGrey,
  //                   // pb: { xs: "23px", md: "0" },
  //                   //   pl: { xs: "22px", lg: "71px" },
  //                   //   width: { xs: "213px", lg: "356px" },
  //                   fontSize: { xs: "24px", lg: "32px" },
  //                   lineHeight: { xs: "26px", lg: "48px" },
  //                   fontFamily: FontFamilyName.BasisGrotesquePro,
  //                   fontWeight: 400,
  //                   // width: { xs: "130px", lg: "254px" },
  //                 }}
  //               >
  //                 {title}
  //               </Typography>
  //             </Grid>
  //             <Grid item xs={4} sm={3}>
  //               <Grid
  //                 container
  //                 direction={"row"}
  //                 justifyContent="flex-end"
  //                 alignItems="center"
  //               >
  //                 <Grid item>
  //                   {isMobile ? (
  //                     <IconButton onClick={onTap}>
  //                       <Box component={"img"} src={ImageAssets.arrowRightPH} />
  //                     </IconButton>
  //                   ) : (
  //                     <ZuluButton
  //                       onClick={onTap}
  //                       color={"info"}
  //                       width={{ xs: "10px", lg: "32px" }}
  //                     >
  //                       <ZuluArrowRight
  //                         color={Colors.textGrey}
  //                         // width={"10"}
  //                         // height={isMobile ? "7" : "10"}
  //                       />
  //                       {/* <mobileArrowRight /> */}

  //                       {/* <Box
  //                     component="img"
  //                     alt="zulu"
  //                     style={{
  //                       fill: Colors.textGrey,
  //                     }}
  //                     sx={{
  //                       content: {
  //                         xs: `url(${ImageAssets.mobileArrowRight})`,
  //                         lg: `url(${ImageAssets.desktopArrowRight})`,
  //                       },
  //                       px: { xs: "0px", lg: "10px" },
  //                       py: { xs: "0px", lg: "5px" },
  //                       color: Colors.textGrey,
  //                     }}
  //                   /> */}
  //                     </ZuluButton>
  //                   )}
  //                 </Grid>
  //               </Grid>
  //             </Grid>
  //           </Grid>
  //         </CardContent>
  //       </CardActionArea>
  //     </Card>
  //   </Box>
  // );
};

export default CardDiscover;
