declare global {
  interface String {
    toPathUrl(): string;
    replaceBR(): string;
    capitalizeFirstLetter(): string;
  }
}

// eslint-disable-next-line no-extend-native
String.prototype.toPathUrl = function () {
  var regex = RegExp(/[^a-zA-Z0-9 ]/g);
  let d = String(this);
  return d.toLowerCase().replaceAll(regex, "").replaceAll(" ", "-");
};

// eslint-disable-next-line no-extend-native
String.prototype.replaceBR = function () {
  let d = String(this);
  return d.replaceAll("<br/>", "\n").replaceAll("<br />", "\n");
};

// eslint-disable-next-line no-extend-native
String.prototype.capitalizeFirstLetter = function () {
  return this[0].toUpperCase() + this.slice(1);
};

export {};
