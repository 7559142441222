import {
  Container,
  Grid,
  Link,
  Typography,
  Box,
  useMediaQuery,
  useTheme,
  Divider,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
} from "@mui/material";
import Colors from "common/const/colorzulu";
import { FontFamilyName, ImageAssets } from "common/const/const_config";
import { ProjectInterface, ProjectInterface2 } from "model/interfaces/project";
import { NavLink, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Masonry from "@mui/lab/Masonry";
import { useEffect } from "react";
import SizeBoxHideonxs from "common/components/sizebox";
import { getImageSize } from "react-image-size";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ZuluButton from "common/components/zulubutton";

import mobileArrowRight from "assets/img/mArrowRight.svg";
import arrowRight from "assets/img/ArrowRight.svg";
import ZuluArrowRight from "common/svg/rightIcon";
import { ImagesType } from "model/types/imagesType";
import Credits from "common/components/credits";
import CardDiscover from "common/components/cardDiscover";
import ZuluConfig from "common/config/zulu_config";

const BodyProjectDetail = (props: { data: ProjectInterface | null }) => {
  const { data } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const navigate = useNavigate();
  const settings = {
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1.25,
    // slidesToShow: 1.25, //3.2,
    slidesToScroll: 1,
    // centerMode: true,
    variableWidth: false,
    swipeToSlide: true,
    edgeFriction: 1,
    arrows: false,
    focusOnSelect: true,
  };

  // const settingsDiscover = {
  //   dots: false,
  //   infinite: false,
  //   speed: 500,
  //   // slidesToShow: isMobile ? 2 : 3, //3.2,
  //   // slidesToScroll: 1,
  //   // centerMode: isMobile,
  //   variableWidth: true,
  //   swipeToSlide: true,
  //   // initialSlide: isMobile ? 0 : 1,
  //   // edgeFriction: 1,
  //   arrows: false,
  //   initialSlide: 0,
  //   // rtl: true,

  //   // fade: false,
  // };
  //   var heightSizeBox: string | null[];
  //   for (let index = 0; index < data?.image?.length; index++) {
  //     const element = data?.image[index];
  //     heightSizeBox.push("asdad");
  //   }
  //   const sizeImage: (string | null)[] = [];
  //   data?.image.forEach(async (item) =>
  //     sizeImage.push(await fetchImageSize(item.baseUrl))
  //   );

  //   useEffect(() => {}, [sizeImage]);

  const DetailProject = () => {
    useEffect(() => {
      window.addEventListener("error", (e) => {
        if (e.message === "ResizeObserver loop limit exceeded") {
          const resizeObserverErrDiv = document.getElementById(
            "webpack-dev-server-client-overlay-div"
          );
          const resizeObserverErr = document.getElementById(
            "webpack-dev-server-client-overlay"
          );
          if (resizeObserverErr) {
            resizeObserverErr.setAttribute("style", "display: none");
          }
          if (resizeObserverErrDiv) {
            resizeObserverErrDiv.setAttribute("style", "display: none");
          }
        }
      });
    }, []);

    return (
      <>
        {/* <Box sx={{ width: "1440px", minHeight: 100 }}> */}
        {/* <Typography
            sx={{
              color: Colors.textGrey,
              pb: { xs: "16px", lg: "0" },
              pt: { xs: "30px", lg: "75px" },
              fontSize: { xs: "28px", lg: "48px" },
              fontFamily: FontFamilyName.BasisGrotesquePro,
              fontWeight: { xs: 500, lg: 400 },
            }}
          >
            Our Product
          </Typography> */}
        {/* <Container maxWidth={"xl"}> */}
        <Masonry
          columns={{ xs: 1, lg: 2 }}
          spacing={{ xs: 1, lg: 5 }}
          // sx={{ backgroundColor: Colors.primaryRed }}
        >
          <Box
          // sx={{ backgroundColor: Colors.primaryRed }}
          >
            <Typography
              sx={{
                color: Colors.textGrey,
                fontFamily: FontFamilyName.BasisGrotesquePro,
                fontSize: { xs: "28px", lg: "72px" },
                fontWeight: 500,
                maxWidth: { xs: "none", lg: "457px" },
                lineHeight: { xs: "33px", lg: "77px" },
                whiteSpace: "pre-wrap",
                //   backgroundColor: Colors.primaryRed,
              }}
            >
              {data?.projectName}
            </Typography>
          </Box>

          {/* <SizeBoxHideonxs height={"189px"} isMobile={!isMobile} /> */}
          {/* <Box border={1}>
            {" "}
            <SizeBoxHideonxs height={"100px"} isMobile={isMobile} />
          </Box> */}

          <Box
            pt={{ xs: "0px", md: "90px" }}
            // border={1}
            minHeight={{ xs: 0, md: "450px" }}
            // border={1}
          >
            <Typography
              sx={{
                color: Colors.textGrey,
                fontFamily: FontFamilyName.Akkurat,
                fontSize: { xs: "13px", lg: "18px" },
                fontWeight: 400,
                width: { xs: "none", lg: "500px" },
                lineHeight: { xs: "14px", lg: "21px" },
                pl: { xs: "none", lg: "80px" },
                whiteSpace: "pre-wrap",
              }}
            >
              {data?.desc}
            </Typography>
          </Box>

          <SizeBoxHideonxs
            height={"30px"}
            width={"100%"}
            isMobile={!isMobile}
          />

          <SizeBoxHideonxs height={"97px"} isMobile={isMobile} />
          <ImageBuilder />
        </Masonry>
        {/* </Box> */}

        <Box
          sx={{
            height: { xs: "39px", lg: "97px" },
          }}
        />
        <Box
          // border={1}
          maxWidth={{ xs: "none", lg: "650px" }}
          // sx={{ backgroundColor: Colors.primaryRed }}
        >
          <Typography
            sx={{
              color: Colors.textGrey,
              fontFamily: FontFamilyName.BasisGrotesquePro,

              fontSize: { xs: "24px", lg: "43px" },
              fontWeight: 400,
              whiteSpace: "pre-wrap",
              lineHeight: { xs: "28px", lg: "56.59px" },
              //   backgroundColor: Colors.primaryRed,
            }}
          >
            {data?.quote}
          </Typography>
        </Box>

        <Box
          sx={{
            height: { xs: "27px", lg: "60px" },
          }}
        />

        {/* </Container> */}
      </>
    );
  };

  const SliderBuilder = () => {
    return (
      <>
        <Slider {...settings}>
          {/* <Box
            sx={{
              width: { xs: "22px", lg: "71px" },
            }}
          /> */}
          {data?.imageSlide?.map((item, index) => (
            <Box
              key={index}
              component="img"
              alt={item.alt}
              // src={data?.imageSlider[0].baseUrl}
              src={ZuluConfig.baseUrlImage + item.baseUrl}
              sx={{
                objectFit: "cover",
                backgroundRepeat: "no-repeat",
                height: { xs: "176px", lg: "600px" },
                width: {
                  xs: "311px",
                  lg: "80%",
                },
                pr: { xs: "10px", lg: "20px" },
                // backgroundColor: Colors.primaryRed,
              }}
              // width={"475px"}
            />
          ))}
        </Slider>
      </>
    );
  };

  const ImageBuilder = () => {
    //         var heightSizeBox: string | null[];
    //   for (let index = 0; index < data?.image?.length; index++) {
    //     const element = data?.image[index];
    //     heightSizeBox.push("asdad");
    //   }

    // console.log(sizeImage);

    //   getImageSize()
    return (
      <>
        {data?.image?.map((item, index) => (
          <>
            <Box
              sx={{
                width: { xs: "90vw", lg: index % 2 === 0 ? "475px" : "711px" },
                ml: { xs: "5vw", lg: "auto" },
                // backgroundColor: Colors.primaryRed,
              }}
            >
              <Box
                // border={1}
                component="img"
                alt={item.alt}
                src={ZuluConfig.baseUrlImage + item.baseUrl}
                sx={{
                  // backgroundColor:Colors.primaryRed,
                  objectFit: "cover",
                  backgroundRepeat: "no-repeat",
                  height: { xs: "245px", sm: "auto", md: "auto", lg: "550px" },
                  width: {
                    xs: "100%",
                    lg: "100%", //index % 2 === 0 ? "475px" : "711px",
                  },
                }}
                // width={"475px"}
              />
            </Box>
            {/* </Box> */}
            <SizeBoxHideonxs
              height={"100px"}
              isMobile={isMobile}
              // color={Colors.primaryRed}
            />
          </>
        ))}
      </>
    );
  };

  return (
    <>
      {/* <Container maxWidth={"xl"} sx={{ px: { xs: "22px", lg: "71px" } }}> */}
      <Box
        sx={{
          px: { xs: "22px", lg: "71px" },
          // pr: { xs: "22px", lg: "90px" },
          //   maxWidth: "1440px",
          //   backgroundColor: Colors.infoBlack,
        }}
      >
        <Link
          component={NavLink}
          to={"/project"}
          variant="button"
          color={Colors.textGrey}
        >
          <Typography
            sx={{
              color: Colors.textGrey,
              fontFamily: FontFamilyName.Akkurat,
              fontSize: { xs: "11px", lg: "16px" },
              fontWeight: 400,
              whiteSpace: "pre-wrap",
            }}
          >
            Project
          </Typography>
        </Link>

        <Box
          sx={{
            height: { xs: "25px", lg: "10px" },
          }}
        />

        {/* <Box component={"img"} onLoad={} /> */}
        <DetailProject />
      </Box>
      {/* </Container> */}

      <SliderBuilder />
      {/* <Container maxWidth={"xl"} sx={{ px: { xs: "22px", lg: "71px" } }}> */}
      {/* <Box sx={{ height: { xs: "19px", lg: "67px" } }} />
      <Box sx={{ px: { xs: "22px", lg: "71px" } }}>
        <Credits data={props.data?.credits} />
      </Box> */}
      {/* <Credits /> */}
      {/* </Container> */}
      <Box sx={{ height: { xs: "40px", lg: "118px" } }} />
      <Divider variant="fullWidth" orientation="horizontal" />
      {/* <Box sx={{ height: { xs: "0px", lg: "69px" } }} /> */}
      {/* <SliderBuilderDiscover /> */}
      {/* <Box sx={{ height: { xs: "24px", lg: "79px" } }} /> */}
    </>
  );
};

// async function fetchImageSize(url: string): Promise<string | null> {
//   try {
//     const dimensions = await getImageSize(url);
//     console.log(dimensions);
//     return dimensions.height.toString() + "px";
//   } catch (error) {
//     console.error(error);
//     return null;
//   }
// }

export default BodyProjectDetail;
