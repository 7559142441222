import { Box } from "@mui/material";
import ZuluConfig from "common/config/zulu_config";
import Colors from "common/const/colorzulu";
import { ImageAssets } from "common/const/const_config";
import { ImagesType } from "model/types/imagesType";

const ResponsiveImage = (props: { image: ImagesType[] }) => {
  const { image } = props;
  return (
    <>
      <Box
        sx={{
          backgroundColor: Colors.primaryRed,
          //   height: { xs: "291px", sm: "450px", lg: "480px" },
          height: { xs: "80vw", sm: "450px", lg: "480px" },
          // width: "100px",
          position: "relative",
          // maxHeight: "203px",
          // height: { xs: "100px", sm: "30vw", md: "20vw", lg: "180px" },
          //   border: 1,
        }}
      >
        <Box
          sx={{
            backgroundColor: Colors.secondayWhite,
            // maxHeight: { xs: "290px", lg: "142px" },
            // height: { xs: "50vw", sm: "80vw", md: "142px" },
            width: "100%",
            // height: { xs: "197px", sm: "70%", lg: "270px" },
            height: { xs: "67%", sm: "70%", lg: "270px" },
            position: "absolute",
            // border: 1,
          }}
        />

        <Box
          component="img"
          alt={image?.[1]?.alt ?? "zulu"}
          src={
            ZuluConfig.baseUrlImage + image?.[1]?.baseUrl ?? ImageAssets.dummy2
          }
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: {
              xs: "translate(-50%, -48%)",
              sm: "translate(-50%, -45%)",
              lg: "translate(-60%, -61%)",
            },
            //   { xs: "9vw", md: "-1vw",lg:"-2", xl:
            //   mt: { md: "-4%", lg: "-3.5%" },
            //   top: "-25%",
            maxWidth: "548px",
            maxHeight: "390px",
            height: "auto",
            width: {
              xs: "90vw",
              md: "100%",
            },
            objectFit: "cover",
            backgroundRepeat: "no-repeat",
          }}
        />

        <Box
          component="img"
          alt={image?.[0]?.alt ?? "zulu"}
          src={
            ZuluConfig.baseUrlImage + image?.[0]?.baseUrl ?? ImageAssets.dummy1
          }
          display={{ xs: "none", lg: "block" }}
          sx={{
            position: "absolute",

            top: "0%",
            right: "25%",
            transform: "translate(90%, -80%)",
            maxWidth: "350px",
            maxHeight: "345px",
            objectFit: "cover",
            backgroundRepeat: "no-repeat",
            width: "330px",
            height: "325px",
            // mr: "5vw",
          }}
        />
      </Box>
    </>
  );
};

export default ResponsiveImage;
