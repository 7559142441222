import { Box, CircularProgress } from "@mui/material";
import ZuluConfig from "common/config/zulu_config";
import Colors from "common/const/colorzulu";
import { ImagesType } from "model/types/imagesType";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { getImageSize } from "react-image-size";

const ImageRenderProject = (props: {
  imageData: ImagesType;
  widthImage: number;
  heightImage: number;
}) => {
  const { imageData, heightImage, widthImage } = props;
  const [imageSize, setImageSize] = useState({
    width: 0,
    height: 0,
    imageProps: {},
  });
  const [isLoading, setIsLoading] = useState(false);

  const runImageSize = async () => {
    setIsLoading(true);

    const imageSrc = ZuluConfig.baseUrlImage + imageData.baseUrl;

    let res = await getImageSize(imageSrc);
    let imageProps = {};
    let biggerWidthThanDefault = res.width >= widthImage;
    let biggerHeightThanDefault = res.height >= heightImage;

    //islandscape
    if (res.width > res.height) {
      imageProps = { width: "100%", height: "100%" };
      // if (biggerWidthThanDefault) {
      //   imageProps = { width: "100%", height: "100%" };
      // } else {
      //   if (heightImage - res.height > 50)
      //     imageProps = {
      //       height: `${heightImage}px`,
      //       width: "100%",
      //     };
      // }
      // console.log(1);
      // console.log(widthImage, heightImage);
      // console.log(res.width, res.height);
      // console.log(imageProps, imageData.alt);
      //ispotrait
    } else if (res.height > res.width) {
      imageProps = { width: "100%", height: "100%" };
      // if (biggerHeightThanDefault) {
      //   imageProps = { width: "100%", height: "100%" };
      // } else {
      //   if (widthImage - res.width < 50)
      //     imageProps = { width: `${widthImage}px` };
      // }
      // console.log(2);
      // console.log(widthImage, heightImage);
      // console.log(res.width, res.height);
      // console.log(imageProps, imageData.alt);
      //issquare
    } else if (res.width === res.height) {
      imageProps = { width: "100%", height: "100%" };
    }

    setImageSize({ ...res, imageProps: { ...imageProps, src: imageSrc } });
  };

  // useEffect(() => {
  //   // runImageSize();
  // }, []);

  // useEffect(() => {
  //   setIsLoading(false);
  // }, [imageSize]);

  return (
    <Box
      //   width={`${widthImage}px`}
      height={"100%"}
      width={"100%"}
      // "100%"
      //   height={`${heightImage}px`}
      // {{ xs: "207.37px", lg: "300px" }}
      justifyContent="center"
      alignItems="center"
      display={"flex"}
      // border={1}
    >
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Box
          component="img"
          alt={imageData.alt}
          src={ZuluConfig.baseUrlImage + imageData.baseUrl}
          // height="100%"
          // width="100%"
          sx={{
            objectFit: "cover",
            backgroundRepeat: "no-repeat",
            width: "100%",
            height: "100%",
          }}
        />
      )}
    </Box>
  );
};

ImageRenderProject.propTypes = {
  imageData: PropTypes.object.isRequired,
  widthImage: PropTypes.number.isRequired,
  heightImage: PropTypes.number.isRequired,
};

export default ImageRenderProject;
