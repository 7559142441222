import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
// import LandingPage from "./module/landingpage/landingpage";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { routes as appRoutes } from "./routes";
import "common/extension/string";
import Page404 from "module/error/page404";
import ScrollToTop from "common/scrooltotop/scrooltotop";
import Layout from "common/tamplate/layout";

// const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <React.StrictMode>
      <Router basename="/">
        <Layout>
          <ScrollToTop />
          <Routes>
            {appRoutes.map((route) => (
              <Route
                key={route.key}
                path={route.path}
                element={<route.component />}
              />
            ))}

            <Route path="notfound" element={<Page404 />} />
            <Route path="*" element={<Navigate to="/notfound" replace />} />
          </Routes>
        </Layout>
      </Router>
      {/* <BrowserRouter>
        <LandingPage />
      </BrowserRouter> */}
    </React.StrictMode>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
