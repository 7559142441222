import { ProductInterface } from "model/interfaces/product";
import { ProjectInterface } from "model/interfaces/project";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  Box,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Colors from "common/const/colorzulu";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CardDiscover from "./cardDiscover";
import FontFamilyName from "common/const/fontfamily";
import { useEffect, useState } from "react";

const SlideDiscover = (props: {
  dataProject: ProjectInterface[] | null;
  dataProduct: ProductInterface[] | null;
}) => {
  const { dataProject, dataProduct } = props;
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isMobileSM = useMediaQuery(theme.breakpoints.down("sm"));
  const [jsonSlide, setJsonSlide] = useState({});

  const pathname = location.pathname.split("/");
  const type = pathname[1].capitalizeFirstLetter();

  const settingsDiscover = {
    dots: false,
    infinite: false,
    speed: 500,
    // slidesToShow: 1.25, //3.2,
    slidesToScroll: 1,
    // centerMode: true,
    variableWidth: true,
    swipeToSlide: true,
    edgeFriction: 1,
    arrows: false,
    initialSlide: 0,

    // fade: false,
  };

  const settingsDiscoverSM = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1.5, //3.2,
    slidesToScroll: 1,
    centerMode: true,
    // variableWidth: true,
    swipeToSlide: true,
    edgeFriction: 1,
    arrows: false,
    initialSlide: 1,

    // fade: false,
  };

  useEffect(() => {
    if (isMobileSM) {
      setJsonSlide({ ...settingsDiscoverSM });
    } else {
      setJsonSlide({ ...settingsDiscover });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobileSM]);

  // var _allDataProduct: ProductInterface2[] = [];
  // dataProduct?.forEach((item: ProductInterface2) => {
  //   if (_allDataProduct.length !== 3) {
  //     if (item.productName.toPathUrl() !== id) {
  //       _allDataProduct.push(item);
  //     }
  //   }
  // });

  // var _allDataProject: ProjectInterface2[] = [];
  // dataProject?.forEach((item: ProjectInterface2) => {
  //   if (_allDataProject.length !== 3) {
  //     if (item.projectName.toPathUrl() !== id) {
  //       _allDataProject.push(item);
  //     }
  //   }
  // });

  const ProductGrid = () => {
    return (
      <>
        {dataProduct?.map((item, index) => (
          <Grid
            // container
            item
            key={index}
            xs={4}
            maxHeight={"315.02px"}
            // border={1}
            // spacing={1}
            // justifyContent={"center"}
            // alignItems={"center"}
          >
            <CardDiscover
              image={item?.imageCover}
              onTap={() => {
                navigate(`/${type}/${item.pathUrl}`);
              }}
              title={item.productName}
              heightImage={251.02}
              widthImage={400}
            />
          </Grid>
        ))}
      </>
    );
  };

  const ProjectGrid = () => {
    return (
      <>
        {dataProject?.map((item, index) => (
          <Grid
            container
            item
            key={index}
            xs={4}
            // container
            // border={1}
            // justifyContent={"center"}
            // alignItems={"center"}
          >
            <CardDiscover
              image={item?.imageCover}
              onTap={() => {
                navigate(`/${type}/${item.pathUrl}`);
              }}
              title={item.projectName}
              heightImage={251.02}
              widthImage={400}
            />
          </Grid>
        ))}
      </>
    );
  };

  let SDProduct = dataProduct?.map((item, index) => (
    <CardDiscover
      key={index}
      image={item?.imageCover}
      onTap={() => {
        navigate(`/${type}/${item.pathUrl}`);
      }}
      title={item.productName}
      heightImage={129}
      widthImage={204}
    />
  ));

  const SDProject = dataProject?.map((item, index) => (
    <CardDiscover
      key={index}
      image={item?.imageCover}
      onTap={() => {
        navigate(`/${type}/${item.pathUrl}`);
      }}
      title={item.projectName}
      heightImage={129}
      widthImage={204}
    />
  ));

  let SDProductSM = dataProduct?.map((item, index) => (
    <CardDiscover
      key={index}
      image={item?.imageCover}
      onTap={() => {
        navigate(`/${type}/${item.pathUrl}`);
      }}
      title={item.productName}
      heightImage={129}
      widthImage={204}
    />
  ));

  const SDProjectSM = dataProject?.map((item, index) => (
    <CardDiscover
      key={index}
      image={item?.imageCover}
      onTap={() => {
        navigate(`/${type}/${item.pathUrl}`);
      }}
      title={item.projectName}
      heightImage={129}
      widthImage={204}
    />
  ));

  return (
    <>
      <Box
        sx={{
          height: { xs: "25px", lg: "69px" },
        }}
      />

      <Typography
        sx={{
          color: Colors.textGrey,
          fontFamily: FontFamilyName.BasisGrotesquePro,
          fontSize: { xs: "24px", lg: "48px" },
          fontWeight: 400,
          pl: { xs: "21px", lg: "70px" },
          lineHeight: { xs: "28px", lg: "56.59px" },
        }}
      >
        {`Discover More ${type}`}
      </Typography>

      <Box
        sx={{
          height: { xs: "10px", lg: "51px" },
        }}
      />
      {!isMobile ? (
        <Box
          pl={{ xs: "22px", md: "22px", lg: "71px" }}
          // sx={{ backgroundColor: Colors.primaryRed }}
        >
          <Grid
            container
            direction={"row"}
            // justifyContent="space-around"
            // alignItems="flex-start"
            // border={1}

            // spacing={1}
            // gap={1}
            // maxWidth={"100%"}
          >
            <ProjectGrid />
            <ProductGrid />
          </Grid>
        </Box>
      ) : (
        <Slider {...(jsonSlide ?? settingsDiscover)}>
          {/* <>
            <Box
              // height={"50px"}
              sx={{
                // backgroundColor: Colors.infoBlack,
                width: { xs: "22px", xl: "71px" },
                // height: "50px",
              }}
            />
          </> */}

          {isMobileSM ? SDProductSM : SDProduct}

          {isMobileSM ? SDProjectSM : SDProject}

          {/* <>
            <Box
              // height={"50px"}
              sx={{
                // backgroundColor: Colors.infoBlack,
                width: { xs: "22px", xl: "71px" },
                height: "50px",
              }}
            />
          </> */}
        </Slider>
      )}
    </>
  );
};

export default SlideDiscover;
