import { Grid, Typography } from "@mui/material";
import Colors from "common/const/colorzulu";
import FontFamilyName from "common/const/fontfamily";
import ZuluLink from "common/const/link";
import { ContactPersonInterface } from "model/interfaces/contact_person";

const ContactPerson = (props: { data?: ContactPersonInterface | null }) => {
  const { data } = props;
  let phoneNumber: string = data?.phone ?? "+628 1234 5678";
  const email: string = data?.email ?? "info@zulu.com";
  const visitUs: string = data?.visitUs ?? "Jakarta Office";
  const address: string =
    data?.address ??
    "Jl. Salak Manis 123, Cikokol,\nKec. Tangerang, Kota Tangerang,\n Banten 15117";

  return (
    <Grid
      container
      direction={"column"}
      // spacing={{ xs: "5px", md: "20px" }}
      rowSpacing={{ xs: "5px", md: "20px" }}
      columnSpacing={{ xs: "0px", md: "20px" }}
      // pl={"21px"}
    >
      <Grid item>
        <Typography
          sx={{
            color: Colors.textGrey,
            fontFamily: FontFamilyName.Akkurat,
            fontSize: { xs: "11px", md: "16px" },
            fontWeight: 400,
            textDecoration: "underline",
            lineHeight: { xs: "14px", md: "21px" },
          }}
        >
          Phone
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          sx={{
            // border: 1,
            color: Colors.textGrey,
            fontFamily: FontFamilyName.BasisGrotesquePro,
            fontSize: { xs: "30px", md: "45px" },
            fontWeight: 500,
            lineHeight: { xs: "46px", md: "50px" },
          }}
          onClick={() =>
            ZuluLink.openlink(
              data?.linkWa ??
                ZuluLink.wa +
                  phoneNumber.replaceAll("+", "").replaceAll(" ", "")
            )
          }
        >
          {phoneNumber}
        </Typography>
      </Grid>

      <Grid item height={{ xs: "10px", lg: "20px" }} />
      <Grid item>
        <Typography
          sx={{
            color: Colors.textGrey,
            fontFamily: FontFamilyName.Akkurat,
            fontSize: { xs: "11px", md: "16px" },
            fontWeight: 400,
            textDecoration: "underline",
            lineHeight: { xs: "14px", md: "21px" },
          }}
        >
          Email
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          sx={{
            color: Colors.textGrey,
            fontFamily: FontFamilyName.BasisGrotesquePro,
            fontSize: { xs: "30px", md: "45px" },
            fontWeight: 500,
            lineHeight: { xs: "46px", md: "50px" },
          }}
          onClick={() => ZuluLink.openlink(ZuluLink.mail + email)}
        >
          {email}
        </Typography>
      </Grid>
      <Grid item height={{ xs: "10px", lg: "20px" }} />
      <Grid item>
        <Typography
          sx={{
            color: Colors.textGrey,
            fontFamily: FontFamilyName.Akkurat,
            fontSize: { xs: "11px", md: "16px" },
            fontWeight: 400,
            textDecoration: "underline",
            lineHeight: { xs: "14px", md: "21px" },
          }}
        ></Typography>
      </Grid>
      <Grid item>
        <Typography
          sx={{
            color: Colors.textGrey,
            fontFamily: FontFamilyName.BasisGrotesquePro,
            fontSize: { xs: "30px", md: "45px" },
            fontWeight: 500,
            lineHeight: { xs: "46px", md: "50px" },
          }}
        >
          {visitUs}
        </Typography>
      </Grid>

      <Grid item mt={{ xs: "14px", md: "0px" }}>
        <Typography
          sx={{
            color: Colors.textGrey,
            fontFamily: FontFamilyName.Akkurat,
            fontSize: { xs: "10px", md: "20px" },
            fontWeight: 400,
            whiteSpace: "pre-wrap",
            lineHeight: { xs: "15px", md: "25.34px" },
          }}
        >
          {address}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ContactPerson;
