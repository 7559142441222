import { useState } from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
  Button,
  Divider,
  Grid,
  Link,
  MenuItem,
} from "@mui/material";

import { FontFamilyName, ImageAssets, Colors } from "common/const/const_config";
import { NavLink, useLocation } from "react-router-dom";
import { routes } from "routes";
import ZuluConfig from "common/config/zulu_config";

function DrawerAppBar(props: any) {
  const location = useLocation();
  // const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isScroll, setScroll] = useState(false);
  const changeColoronScroll = () => {
    if (window.scrollY >= 173) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  window.addEventListener("scroll", changeColoronScroll);

  const colorNavbar = (path: string) => {
    // location.pathname === "/" && isScroll
    // ? item.path === location.pathname? Colors.textGrey
    //     : location.pathname === "/" && !isScroll ?

    if (location.pathname === "/") {
      if (location.pathname.includes(path)) {
        return Colors.textGrey;
      } else {
        return Colors.secondayWhite;
      }
    } else {
      if (location.pathname.includes(path)) {
        return Colors.primaryRed;
      } else {
        return Colors.textGrey;
      }
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  // <Link
  //   key={page.key}
  //   component={NavLink}
  //   to={page.path}
  //   color="black"
  //   underline="none"
  //   variant="button"
  // >
  //    <MenuItem onClick={handleCloseNavMenu}>
  //      <Typography textAlign="center">{page.title}</Typography>
  //   </MenuItem>
  // </Link>;

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Box pl={"22px"} pr={"22px"} pt={"26px"}>
        <Grid container direction={"row"} justifyContent={"space-between"}>
          <Grid item sx={{ pl: 2, pt: 1 }}>
            <Link
              component={NavLink}
              to={"/"}
              // href="/"
              // variant="body1"
              // underline="hover"
              // target="_blank"
              // rel="noopener noreferrer"
            >
              <Box
                component="img"
                alt="zulu"
                src={ImageAssets.MainLogo}
                width={"64px"}
              />
            </Link>
          </Grid>
          <Grid item>
            <IconButton>
              <Box
                component="img"
                alt="close"
                src={ImageAssets.Ic_Close}
                sx={{ textDecoration: "none", cursor: "pointer" }}
              />
            </IconButton>
          </Grid>
        </Grid>
        <List>
          {/* {routes.map((page) => ( */}
          {routes.map((item, index) =>
            item.enabled ? (
              <Link
                key={item.key}
                component={NavLink}
                to={item.path}
                color="black"
                underline="none"
                variant="button"
              >
                <MenuItem
                  selected={location.pathname.includes(item.path)}
                  sx={{
                    pb: "20px",
                  }}
                >
                  <Typography
                    sx={{
                      // textAlign: "left",
                      fontFamily: FontFamilyName.BasisGrotesquePro,
                      fontSize: "24px",
                      color: Colors.textGrey,
                    }}
                  >
                    {item.title}
                  </Typography>
                </MenuItem>
                {/* <Divider variant="fullWidth" orientation="horizontal" /> */}
              </Link>
            ) : (
              // {/* <Link
              //   key={page.key}
              //   component={NavLink}
              //   to={page.path}
              //   color="black"
              //   underline="none"
              //   variant="button"
              // ></Link>
              // <ListItem key={item.key} disablePadding>
              //   <ListItemButton
              //     LinkComponent={NavLink}
              //     href={item.path}
              //     sx={{
              //       textAlign: "left",
              //       fontFamily: FontFamilyName.BasisGrotesquePro,
              //       fontSize: "24px",
              //       color: Colors.textGrey,
              //     }}
              //   >
              //     <ListItemText primary={item.title} />
              //   </ListItemButton>
              // </ListItem>
              // <Divider variant="fullWidth" orientation="horizontal" /> */}
              <Box key={item.key} />
            )
          )}
        </List>
      </Box>
    </Box>
  );

  // const container =
  // window !== undefined ? () => window().document.body : undefined;

  // console.log(container);

  return (
    <Box bgcolor={Colors.primaryRed} border={1}>
      {/* <CssBaseline /> */}
      <AppBar
        component="nav"
        color={
          location.pathname === "/"
            ? isScroll
              ? "primary"
              : "transparent"
            : "secondary"
        }
        elevation={0}
        style={{
          boxShadow: "none",
        }}
        sx={{
          height: {
            xs: "73px",
            lg: "65px",
          },
          borderBottom: 1,
          borderColor:
            location.pathname === "/" ? Colors.secondayWhite : Colors.textHint,
        }}
      >
        <Toolbar
          disableGutters
          sx={{
            width: "100%",
            maxWidth: ZuluConfig.withMaxWidth ? "1440px" : "100%",
            mx: "auto",
          }}
        >
          <Link component={NavLink} to="/">
            <Box
              // border={1}
              component="img"
              alt="zulu"
              src={
                location.pathname === "/"
                  ? ImageAssets.Logo
                  : ImageAssets.MainLogo
              }
              // minwidth={{
              //     mobile: '64px',
              //     tablet: '64px',
              //     laptop: '91px',
              //     desktop: '91px'
              // }}
              sx={{
                // width: { xs: "64px", sm: "64px", md: "91px", xl: "91px" },
                minWidth: {
                  xs: "64px",
                  lg: "91px",
                },
                pt: {
                  xs: "17px",
                  lg: "25px",
                },
                pb: {
                  xs: "17px",
                  lg: "auto",
                },
                ml: {
                  xs: "22px",
                  lg: "71px",
                },
              }}
            />
          </Link>
          <Typography
            component="div"
            sx={{
              flexGrow: 1,
              pl: {
                xs: "22px",
                lg: "71px",
              },
              py: {
                xs: "28px",
                lg: "19px",
              },
            }}
          ></Typography>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerToggle}
            sx={{ display: { md: "none" } }}
          >
            <Typography
              color="initial"
              sx={{
                color:
                  location.pathname === "/"
                    ? Colors.secondayWhite
                    : Colors.textGrey,
                fontSize: "16px",
                pr: "30px",
                pt: "26px",
                pb: "24.69px",
                fontFamily: FontFamilyName.Akkurat,
              }}
            >
              Menu
            </Typography>
          </IconButton>

          <Box
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "block",
                // pr: "69px",
              },
            }}
          >
            <Grid
              container
              direction={"row"}
              justifyContent={"center"}
              // border={1}
            >
              {location.pathname !== "/" ? (
                <Link
                  component={NavLink}
                  to={"/"}
                  color="black"
                  underline="none"
                  variant="button"
                >
                  <Typography
                    // border={1}
                    sx={{
                      color: colorNavbar("/Home"),
                      fontSize: "16px",
                      pr: "65px",
                      // pt: "20px",
                      pb: "2px",
                      fontFamily: FontFamilyName.Akkurat,
                      textUnderlineOffset: "3px",
                      // border-bottom:1px solid black;

                      // textDecoration: location.pathname.includes(item.path)
                      //   ? "underline"
                      //   : "none",
                      "&:hover": {
                        textDecoration: "underline",
                        textUnderlineOffset: "3px",
                        // textDecorationColor: Colors.textGrey, //Colors.primaryRed,
                        textDecorationColor:
                          location.pathname === "/"
                            ? Colors.secondayWhite
                            : Colors.textGrey,
                      },
                    }}
                  >
                    Home
                  </Typography>
                </Link>
              ) : (
                <></>
              )}

              {routes.map((item, index) =>
                item.enabled ? (
                  <Link
                    key={item.key}
                    component={NavLink}
                    to={item.path}
                    color="black"
                    underline="none"
                    variant="button"
                  >
                    <Typography
                      sx={{
                        color: colorNavbar(item.path),
                        fontSize: "16px",
                        pr: routes.length - 1 === index ? "89px" : "65px",
                        // pt: "20px",
                        pb: "2px",
                        fontFamily: FontFamilyName.Akkurat,
                        textUnderlineOffset: "3px",
                        // border-bottom:1px solid black;

                        // textDecoration: location.pathname.includes(item.path)
                        //   ? "underline"
                        //   : "none",
                        "&:hover": {
                          textDecoration: location.pathname.includes(item.path)
                            ? "none"
                            : "underline",
                          textUnderlineOffset: "3px",
                          // textDecorationColor: Colors.textGrey, //Colors.primaryRed,
                          textDecorationColor:
                            location.pathname === "/"
                              ? Colors.secondayWhite
                              : Colors.textGrey,
                        },
                      }}
                    >
                      {item.title}
                    </Typography>
                  </Link>
                ) : (
                  <Box key={item.key} />
                )
              )}
            </Grid>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          // container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          anchor="right"
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: "100%",
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

DrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerAppBar;
