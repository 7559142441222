import logo from "assets/img/zulu-logo.svg";
import mainLogo from "assets/img/zulu-main-logo.svg";
import bgImage from "assets/img/background.png";
import mobileArrowRight from "assets/img/mArrowRight.svg";
import arrowRight from "assets/img/ArrowRight.svg";

import arrowRightPH from "assets/img/ArrowRightph.svg";
import dummy1 from "assets/img/photo1.png";
import dummy2 from "assets/img/photo2.png";
import p1s from "assets/img/project/p1s.png";
import p1l from "assets/img/project/p1l.png";
import p2s from "assets/img/project/p2s.png";
import p2l from "assets/img/project/p2l.png";
import p3s from "assets/img/project/p3s.png";
import p3l from "assets/img/project/p3l.png";
import sampleTeam from "assets/img/team/sample.png";
import gloves from "assets/img/product/gloves.png";
import helm from "assets/img/product/helm.png";
import n95mask from "assets/img/product/n95mask.png";
import sportmask from "assets/img/product/sportmask.png";
import redGloves from "assets/img/product/glovesred.png";
import osirisChrome from "assets/img/product/osirischrome.png";
import sampleBody from "assets/img/product/samplebody.png";
import spotify from "assets/img/spotify.svg";
import twitter from "assets/img/twitter.svg";
import linkedIn from "assets/img/linkedin.svg";
import ig from "assets/img/ig.svg";
import icClose from "assets/img/ic_close.svg";
import slide1 from "assets/img/project/slide1.png";
import slide2 from "assets/img/project/slide2.png";
import error404 from "assets/img/error404.png";
import sampleAbout from "assets/img/sampleabout.png";

import lazada from "assets/img/lazada.svg";
import tokopedia from "assets/img/tokopedia.svg";
import shopee from "assets/img/shopee.svg";

import gojek from "assets/img/gojek.png";
import zulu from "assets/img/zulu.png";
import nafas from "assets/img/nafas.png";

class ImageAssets {
  static MainLogo = mainLogo;
  static Logo = logo;
  static Twitter = twitter;
  static IG = ig;
  static Spotify = spotify;
  static Ic_Close = icClose;
  static LinkedIn = linkedIn;

  static BackgroundMain = bgImage;
  static mobileArrowRight = mobileArrowRight;
  static desktopArrowRight = arrowRight;
  static arrowRightPH = arrowRightPH;

  static dummy1 = dummy1;
  static dummy2 = dummy2;

  static p1s = p1s;
  static p1l = p1l;
  static p2s = p2s;
  static p2l = p2l;
  static p3s = p3s;
  static p3l = p3l;
  static slide1 = slide1;
  static slide2 = slide2;

  static sampleTeam = sampleTeam;
  static sampleAbout = sampleAbout;

  static gloves = gloves;
  static helm = helm;
  static n95mask = n95mask;
  static sportmask = sportmask;
  static osisrisChrome = osirisChrome;
  static redGloves = redGloves;

  static sampleBodyProductDetail = sampleBody;

  static lazada = lazada;
  static shopee = shopee;
  static tokopedia = tokopedia;

  static gojek = gojek;
  static zulu = zulu;
  static nafas = nafas;

  static error404 = error404;
}

export default ImageAssets;
