// src/routes.ts

// pages
import About from "module/about/about";
import Home from "./module/landingpage/landingpage";
import Project from "./module/project/project";

// other
import { FC } from "react";
import Product from "module/product/product";
import Service from "module/service/service";
import Contact from "module/contact/contact";
import ProjectDetailPage from "module/projectdetail/projectdetail";
import ProductDetail from "module/productdetail/productdetail";

// interface
interface Route {
  key: string;
  title: string;
  path: string;
  enabled: boolean;
  component: FC<{}>;
}
// const navItems = ["About", "Project", "Product", "Service", "Contact"];
export const routes: Array<Route> = [
  {
    key: "home-route",
    title: "Home",
    path: "/",
    enabled: false,
    component: Home,
  },
  {
    key: "about-route",
    title: "About",
    path: "/about",
    enabled: true, //set false
    component: About,
  },
  {
    key: "project-route",
    title: "Project",
    path: "/project",
    enabled: true,
    component: Project,
  },

  {
    key: "product-route",
    title: "Product",
    path: "/product",
    enabled: true,
    component: Product,
  },
  {
    key: "services-route",
    title: "Services",
    path: "/services",
    enabled: true, // set false
    component: Service,
  },
  {
    key: "contact-route",
    title: "Contact",
    path: "/contact",
    enabled: true,
    component: Contact,
  },
  {
    key: "project-detail-route",
    title: "Project Detail",
    path: "/project/:id",
    enabled: false,
    component: ProjectDetailPage,
  },
  {
    key: "product-detail-route",
    title: "Product Detail",
    path: "/product/:id",
    enabled: false,
    component: ProductDetail,
  },
];
