import { Box } from "@mui/material";
import PropTypes from "prop-types";
const SizeBoxHideonxs = (props: any) => {
  return (
    <>
      {!props.isMobile ? (
        <Box
          sx={{
            backgroundColor: props.color ?? "transparent",
            height: props.height,
            width: props.width ?? 0,
            // display: { xs: "none", lg: "block" },
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};

SizeBoxHideonxs.prototype = {
  height: PropTypes.string.isRequired,
  width: PropTypes.string,
  color: PropTypes.string,
  isMobile: PropTypes.bool,
};

export default SizeBoxHideonxs;
