import { Box } from "@mui/material";

const SpaceingAppBar = () => {
  return (
    <Box
      sx={{
        height: { xs: "73.22px", lg: "65px" },
      }}
    />
  );
};

export default SpaceingAppBar;
