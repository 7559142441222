import {
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  IconButton,
  MobileStepper,
  Button,
  CircularProgress,
} from "@mui/material";
import ZuluButton from "common/components/zulubutton";

import { Colors, FontFamilyName, ImageAssets } from "common/const/const_config";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

import PropTypes from "prop-types";
import { SlideLandingPageInterface2 } from "model/interfaces/slideLandingPage";
import "../style/fullimage.css";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { CoverInterface } from "model/interfaces/cover";
import { ApiController } from "common/api/api_controller";
import ZuluConfig from "common/config/zulu_config";
import { useNavigate } from "react-router-dom";
import SpaceingAppBar from "common/components/spacingappbar";

const FullImage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const [data, setData] = useState<CoverInterface[] | null>(null);
  const [isLoading, setLoading] = useState(false);

  async function getData() {
    setLoading(true);
    let respon = await ApiController.getAllCover();

    setData([...respon]);
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setLoading(false);
    // console.log(data);
  }, [data]);

  const settings = {
    autoplay: true,
    autoplaySpeed: 3000,
    dots: true,
    // infinite: false,
    speed: 500,
    // slidesToShow: 1,
    // slidesToScroll: 1,
    // centerMode: true,
    // // variableWidth: true,
    // swipeToSlide: false,
    // edgeFriction: 1,
    arrows: false,
    // // initialSlide: isMobile ? 0 : 2,
    // focusOnSelect: true,
    // adaptiveHeight: true,
    prevArrow: (
      <span className="fas fa-arrow-left slick-arrow slick-arrow-soft-white slick-arrow-left slick-arrow-centered-y rounded-circle ml-sm-2 ml-xl-4"></span>
    ),
    nextArrow: (
      <span className="fas fa-arrow-right slick-arrow slick-arrow-soft-white slick-arrow-right slick-arrow-centered-y rounded-circle mr-sm-2 mr-xl-4"></span>
    ),

    appendDots: (dots: any) => {
      return (
        <Box mb={"50px"}>
          <ul
            style={{
              // marginBottom: "50px",
              padding: 0,
              // width: "20%",
              backgroundColor: Colors.textHint,
              borderRadius: "30px",
              display: "inline-flex",
              // whiteSpace: "nowrap",
              // white-space:nowrap;
              // textAlign: "center",
              opacity: 0.7,
            }}
          >
            {dots}
          </ul>
        </Box>
      );
    },

    // beforeChange: (prev: any, next: any) => {
    //   // here to detect slide change
    //   // this.setState({ currentSlideIndex: next });
    // },
    // customPaging: (index: number) => {
    //   const style = {
    //     width: 13,
    //     height: 13,
    //     display: "inline-block",
    //     // backgroundImage: `url(../navigator.png )`, // need to change as navigator-active.png this when active
    //     backgroundSize: "contain",
    //     backgroundRepeat: "no-repeat",
    //   };
    //   const activeStyle = {
    //     width: 13,
    //     height: 13,
    //     display: "inline-block",
    //     // backgroundImage: `url(../navigator-active.png )`,
    //     backgroundSize: "contain",
    //     backgroundRepeat: "no-repeat",
    //   };
    //   return (
    //     <a className="slick-dot" style={index == 0 ? activeStyle : style} />
    //   );
    // },
  };

  const ContainHeader = (props: { item: CoverInterface }) => {
    const { item } = props;
    const urlImage =
      item.mobileImage !== null
        ? item.mobileImage?.baseUrl
        : item.image?.baseUrl;
    const imagesrc =
      item.image != null
        ? isMobile
          ? encodeURI(ZuluConfig.baseUrlImage + urlImage)
          : encodeURI(ZuluConfig.baseUrlImage + item.image?.baseUrl)
        : ImageAssets.BackgroundMain;
    return (
      <Box
        sx={{
          background: `url('${imagesrc}')`,
          height: "100vh",
          loading: "lazy",
          // height: { xs: "667px", lg: "1225px" },
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <SpaceingAppBar />
        <Box pl={{ xs: "22px", lg: "71px" }}>
          <Typography
            sx={{
              color: "white",
              // pt: { xs: "116px", lg: "207px" },
              // pl: { xs: "22px", lg: "71px" },
              // width: { xs: "213px", lg: "356px" },
              fontSize: { xs: "48px", lg: "100px" },
              lineHeight: { xs: "62px", lg: "100px" },
              fontFamily: FontFamilyName.BasisGrotesquePro,
              fontWeight: { xs: 400, lg: 500 },
              whiteSpace: "pre-wrap",
            }}
            // border={1}
          >
            {item.text.replaceBR()}
            {/* Turning Vision Into Reality */}
          </Typography>

          <Box pt={{ xs: "11px", lg: "40px" }}>
            {item.button ? (
              <ZuluButton
                onClick={() => navigate(item.link)}
                color={"secondary"}
              >
                <Box
                  component="img"
                  alt="zulu"
                  sx={{
                    content: {
                      xs: `url(${ImageAssets.mobileArrowRight})`,
                      lg: `url(${ImageAssets.desktopArrowRight})`,
                    },
                    px: { xs: "6px", lg: "15px" },
                    py: { xs: "10px", lg: "10px" },
                  }}
                ></Box>
              </ZuluButton>
            ) : (
              <></>
            )}
          </Box>
        </Box>
      </Box>
    );
  };

  return isLoading ? (
    <Box
      height={"100vh"}
      justifyContent={"center"}
      alignItems={"center"}
      bgcolor={Colors.infoBlack}
      display={"flex"}
    >
      <CircularProgress />
    </Box>
  ) : (
    <Slider
      {...settings}
      // customPaging={(index) => <></>}
    >
      {data?.map((item, index) => {
        return <ContainHeader key={index} item={item} />;
      })}
    </Slider>
  );
};

export default FullImage;
